import {Textfield} from '@panviva/panviva-react-ui';

const TextInput = (props: any) => {
    return (
        <div style={{minWidth: '100%'}}>
            <Textfield name={props.title} value={props.value} {...props} />
        </div>
    );
};

export default TextInput;
