// import {NavLink} from 'react-router-dom';
import { Button } from '@panviva/panviva-react-ui';
import { TenantGrid } from '../../components/tenants/tenantListGrid';
import { TitleBar } from '@panviva/panviva-react-ui';

export default function Tenants() {
    return (
        <div className="up-h-full up-w-full">
            <div
                className="up-bg-gray-10 up-items-center"
            >
                <TitleBar title="Tenants" backOption={false}>
                    <Button
                        icon="add"
                        label="Add"
                        variant="outlined"
                        href="/tenant/create/"
                    />
                </TitleBar>
                <TenantGrid />
            </div>
        </div>
    );
}
