import axios, {AxiosResponse} from 'axios';
import config from '../app/config';

const getAuthToken = () => localStorage.getItem('accessToken');
const isSuccessfulCreatedResponse = (response: AxiosResponse<any, any>) =>
    response.status === 201;
const isSuccessResponse = (response: AxiosResponse<any, any>) =>
    response.status === 200 || isSuccessfulCreatedResponse(response);

export const ensureSuccessResponse = (response: AxiosResponse<any, any>) => {
    if (!isSuccessResponse(response)) {
        throw response.data;
    }

    return response;
};

export const ensureAcceptedResponse = (response: AxiosResponse<any, any>) => {
    if (response.status !== 202) {
        throw response.data;
    }

    return response;
};

export const ensureSuccessfulCreationResponse = (
    response: AxiosResponse<any, any>
) => {
    if (!isSuccessfulCreatedResponse(response)) {
        throw response.data;
    }

    return response;
};

export default () =>
    axios.create({
        baseURL: config.apiBaseUrl,
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${getAuthToken()}`
        }
    });
