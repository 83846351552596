import React from 'react';
import {useHistory} from 'react-router-dom';
import { Loader, SupportedEntities } from '../grid/dataLoader';
import { AsyncDropDownColumnMenu } from '../grid/asyncDropDownFilter';
import CountryList from '../../common/data/countries';
import { DateColumnMenu } from '../grid/dateFilter';
import { NavLink } from 'react-router-dom';
import { NumericColumnMenu } from '../grid/numericFilter';
import RegionsList from '../../common/data/regions';
import { TextColumnMenu } from '../grid/textFilter';
import getHttpClient, { ensureSuccessResponse } from '../../common/http-common';
import { IdentityProviderNames } from '../../common/data/identityProviders';
import { convertDateToLocale } from '../../common/utils';
import { AlertBar, Button, Dropdown, HeaderField, Table, TableBody, TableField, TableHeader, TableRow, Textfield, UpIcon } from '@panviva/panviva-react-ui';
import { IDataResult, IDataState } from '../grid/dataLoader';
import { AlertColor } from '../../common/types';
import '../grid/gridStyles.scss';
import '../../style/App.scss';

enum GridColumns {
    Name = 'name',
    Code = 'code',
    Region = 'region',
    Country = 'country',
    NumberOfUsers = 'numberOfUsers',
    UpdatedDate = 'updateDateTime',
    AllowedIdentityProvidersGenerated = 'allowedIdentityProvidersGenerated'
}

const GridFields = {
    [GridColumns.Name]: 'name',
    [GridColumns.Code]: 'code',
    [GridColumns.Region]: 'region',
    [GridColumns.Country]: 'country',
    [GridColumns.NumberOfUsers]: 'numberOfUsers',
    [GridColumns.UpdatedDate]: 'updateDateTime',
    [GridColumns.AllowedIdentityProvidersGenerated]:
        'allowedIdentityProvidersGenerated'
};

const GridTitles = {
    [GridColumns.Name]: 'Organization Name',
    [GridColumns.Code]: 'Organization Code',
    [GridColumns.Region]: 'Region',
    [GridColumns.Country]: 'Country',
    [GridColumns.NumberOfUsers]: 'Users',
    [GridColumns.UpdatedDate]: 'Last Activity',
    [GridColumns.AllowedIdentityProvidersGenerated]:
        'Allowed Identity Providers'
};

type IIdentityProvider = {
    name: string;
    identityProviderId: string;
};

enum ResponseFields {
    Name = 'name',
    Code = 'code',
    Region = 'region',
    Country = 'country',
    NumberOfUsers = 'numberOfUsers',
    AllowedIdentityProviders = 'allowedIdentityProviders',
    UpdatedDate = 'updateDateTime'
}

interface IOrganiation {
    [ResponseFields.Name]: string;
    [ResponseFields.Code]: string;
    [ResponseFields.Region]: string;
    [ResponseFields.Country]: string;
    [ResponseFields.NumberOfUsers]: string;
    [ResponseFields.UpdatedDate]: string;
    [ResponseFields.AllowedIdentityProviders]: Array<IIdentityProvider>;
}

const transformData = (data: IOrganiation[]) => {
    return data.map((item) => ({
        [GridFields[GridColumns.Name]]: item[ResponseFields.Name],
        [GridFields[GridColumns.Code]]: item[ResponseFields.Code],
        [GridFields[GridColumns.Region]]: item[ResponseFields.Region],
        [GridFields[GridColumns.Country]]: item[ResponseFields.Country],
        [GridFields[GridColumns.NumberOfUsers]]:
            item[ResponseFields.NumberOfUsers],
        [GridFields[GridColumns.UpdatedDate]]: item[ResponseFields.UpdatedDate],
        [GridFields[GridColumns.AllowedIdentityProvidersGenerated]]: item[
            ResponseFields.AllowedIdentityProviders
        ]
            ?.map((i) => i.name)
            .join(', ')
    }));
};

export const OrganizationGrid = (): JSX.Element => {
    const history = useHistory();
    const loader = React.useRef<React.ElementRef<typeof Loader>>(null);
    const [notification, setNotification] = React.useState<{
        text: string;
        severity: AlertColor;
    }>();
    const [searchString, setSearchString] = React.useState('');
    const [appliedSearchString, setAppliedSearchString] = React.useState<
        string | undefined
    >(undefined);
    const [organizations, setOrganizations] = React.useState<IDataResult>({ data: [], total: 0 });
    const [dataState, setDataState] = React.useState<IDataState>({
        take: 10,
        skip: 0,
        page: 1,
        filter: { logic: 'and', filters: [] }
    });

    const handleDelete = (code: string) => {
        (async () => {
            await getHttpClient()
                .delete(
                    `organization/${code}`
                )
                .then(ensureSuccessResponse)
                .then(() => {
                    setNotification({
                        text: 'Deleted organization',
                        severity: 'information'
                    });

                    if (loader.current) {
                        loader.current.refreshData();
                    }
                })
                .catch((err) => {
                    setNotification({
                        text: 'Could not delete organiation',
                        severity: 'error'
                    });
                });
        })();
    };

    const dataReceived = (organizationsResult: IDataResult) => {
        setOrganizations(organizationsResult);
    };

    const closeNotification = () => {
        setNotification(undefined);
    };

    const filterChanged = () => {
        setAppliedSearchString(searchString);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setDataState({ ...dataState, take: parseInt(event.target.value, 10), skip: 0, page: 1 })
    }

    const handleFirstPageChange = () => {
        setDataState({ ...dataState, skip: 0, page: 1 });
    }

    const handlePrevPageChange = () => {
        setDataState({ ...dataState, skip: dataState.skip - dataState.take, page: dataState.page - 1 })
    }

    const handleNextPageChange = () => {
        setDataState({ ...dataState, skip: dataState.skip + dataState.take, page: dataState.page + 1 })
    }

    const handleLastPageChange = () => {
        setDataState({ ...dataState, skip: dataState.page * (dataState.take), page: Math.ceil(organizations.total / dataState.take) })
    }

    const handleFilterApply = (field: string, value: any, operator:string="eq", hasSecondFilter:boolean = false) => {
        if(hasSecondFilter){
            handleSecondFilter(field, value);
        }else{
        const newObj = { field, value, operator };
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        const index = dataState.filter.filters.findIndex(obj => obj.field === field);
        if (index > -1) {
            value ? updatedFilters[index].value = value : updatedFilters.splice(index, 1)
        } else {
            value && updatedFilters.push(newObj);
        }
        setDataState({ ...dataState, filter: { logic: 'and', filters: updatedFilters } });
        }
    }

    const handleSecondFilter = (field: string, value: any) =>{
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        Object.keys(value).forEach((element:any) => {
            const index = dataState.filter.filters.findIndex(obj => obj.field === field && obj.operator === value[element].operator);
            const newObj = { field, value: value[element].value , operator: value[element].operator };
            if (index > -1) {
                value ? updatedFilters[index].value = value[element].value : updatedFilters.splice(index, 1)
            } else {
                value && updatedFilters.push(newObj);
            }
        });
        setDataState({ ...dataState, filter: { logic: 'and', filters: updatedFilters } });
    }

    const handleFilterClear = (field: string) => {
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        const filteredList = updatedFilters.filter((obj:any) => obj.field !== field);
        setDataState({ ...dataState, filter: { logic: 'and', filters: filteredList } });
    }

    return (
        <div>
            <div
                className="up-w-full up-flex up-bg-blue-0"
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    minHeight: 'fit-content',
                    padding: 2
                }}
            >
                <Textfield
                    label="Search"
                    icon="search"
                    value={searchString || ''}
                    style={{
                        width: 227,
                        paddingRight: 14,
                        marginBottom: 8
                    }}
                    onChange={(e: any) => {
                        setSearchString(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            filterChanged();
                        }
                    }}
                    onClickIcon={() => filterChanged()}
                />
            </div>

            <Table
                className={'table-container'}
                totalCount={organizations.total}
                totalPages={Math.ceil(organizations.total / dataState.take)}
                currentPage={dataState.page}
                rowsPerPage={dataState.take}
                setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeRowsPerPage(e)}
                handleFirstPage={handleFirstPageChange}
                handlePrevPage={handlePrevPageChange}
                handleNextPage={handleNextPageChange}
                handleLastPage={handleLastPageChange}
            >
                <TableHeader>
                    <TableRow type="header" style={{ fontSize: '14px' }}>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Name]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.Name]}
                                    field={GridFields[GridColumns.Name]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Name])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Code]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.Code]}
                                    field={GridFields[GridColumns.Code]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Code])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Region]}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.Region]}
                                    field={GridFields[GridColumns.Region]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Region])?.value}
                                    menuOptions={RegionsList}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Country]}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.Country]}
                                    field={GridFields[GridColumns.Country]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Country])?.value}
                                    asyncloader={CountryList}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.NumberOfUsers]}{' '}
                                <NumericColumnMenu
                                    title={GridTitles[GridColumns.NumberOfUsers]}
                                    field={GridFields[GridColumns.NumberOfUsers]}
                                    firstFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.NumberOfUsers] && obj.operator === 'ge')?.value}
                                    secondFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.NumberOfUsers] && obj.operator === 'le')?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.AllowedIdentityProvidersGenerated]}{' '}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.AllowedIdentityProvidersGenerated]}
                                    field={GridFields[GridColumns.AllowedIdentityProvidersGenerated]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.AllowedIdentityProvidersGenerated])?.value}
                                    asyncloader={IdentityProviderNames}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.UpdatedDate]}{' '}
                                <DateColumnMenu
                                    title={GridTitles[GridColumns.UpdatedDate]}
                                    field={GridFields[GridColumns.UpdatedDate]}
                                    firstFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.UpdatedDate] && obj.operator === 'ge')?.value}
                                    secondFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.UpdatedDate] && obj.operator === 'le')?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            {" "}
                        </HeaderField>
                    </TableRow>
                </TableHeader>
                {organizations.total ?
                    <TableBody>
                        {organizations?.data?.map(dataItem => {
                            return <TableRow key={dataItem[GridFields[GridColumns.Code]]}>
                                <TableField>
                                    <NavLink
                                        key={dataItem[GridFields[GridColumns.Code]]}
                                        to={`organization/details/${dataItem[GridFields[GridColumns.Code]]
                                            }`}
                                        className="label up-body-2"
                                        style={{ color: '#2574db', textDecoration: 'none' }}
                                    >
                                        {
                                            dataItem[
                                            GridFields[GridColumns.Name]
                                            ]
                                        }
                                    </NavLink>
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Code]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Region]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Country]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.NumberOfUsers]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.AllowedIdentityProvidersGenerated]]}
                                </TableField>
                                <TableField>
                                    <span className="up-caption text-color-gray-30">
                                        Updated
                                    </span>
                                    <br />
                                    {convertDateToLocale(
                                        dataItem[
                                        GridFields[GridColumns.UpdatedDate]
                                        ]
                                    )}
                                </TableField>
                                <TableField>                                    
                                        <Dropdown
                                            id="single-menu"
                                            label="SingleMenu"                                            
                                            iconType
                                            menuContainerClassName="margin_left_negative"
                                        >
                                        <div style={{backgroundColor:"white"}}>
                                            <Button
                                                variant="text"
                                                label='Edit'
                                                color='gray'
                                                capitalize                                           
                                                onClick={() => {history.push(`organization/edit/${dataItem[GridColumns.Code]}`)}}
                                            />
                                            <Button
                                                variant="text"
                                                label='Delete'
                                                color='gray'
                                                capitalize
                                                onClick={() => handleDelete(dataItem[GridColumns.Code])}    
                                            />
                                        </div>
                                        </Dropdown>
                                </TableField>
                            </TableRow>
                        })}
                    </TableBody>
                    :
                    <div className='table-empty'>No rows to display</div>
                }
            </Table>

            <Loader
                ref={loader}
                dataState={dataState}
                searchString={appliedSearchString}
                searchStringSupportedFields={[
                    ResponseFields.Name,
                    ResponseFields.Code,
                    ResponseFields.Region,
                    GridFields[GridColumns.AllowedIdentityProvidersGenerated]
                ]}
                nestedObjectsToQuery={{
                    [GridFields[GridColumns.AllowedIdentityProvidersGenerated]]:
                    {
                        parent: 'allowedIdentityProviders',
                        child: 'name'
                    }
                }}
                onDataReceived={dataReceived}
                entityName={SupportedEntities.Organization}
                transformData={transformData}
            />

            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};
