import {Typography, UpIcon} from '@panviva/panviva-react-ui';
import {FunctionComponent} from 'react';

const PageUnderConstruction: FunctionComponent = () => {
    return (
        <div
            className="up-bg-gray-10 up-w-full up-h-full up-items-center up-justify-center up-mx-auto up-my-auto up-pt-10"
            style={{textAlign: 'center'}}
        >
            {/* <UpIcon
                icon="warning"
                label="Warning"
                style={{fontSize: '50px', color: '#838fa0'}}
            /> */}

            <Typography
                variant="subtitle_1"
                className=" up-font-bold"
                style={{fontSize: '50px', color: '#838fa0'}}
            >
                {/* Page under construction */}
                Welcome to Enterprise User Management.
            </Typography>

            <Typography
                variant="title"
                className=" up-font-semibold"
                style={{color: '#838fa0'}}
            >
                <br />
                {/* The page you are looking for is temporarily unavailable. */}
                The Dashboard page is under construction.
                <br />
                <br />
                Please explore users listing menu
                {/* Please try after sometime. Else, contact Support. */}
            </Typography>
        </div>
    );
};

export default PageUnderConstruction;
