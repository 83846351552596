/* eslint-disable @typescript-eslint/no-unused-vars */
import {IOrganization, add, get, patch} from './organizationService';
import {SerializedError, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {ensureSuccessResponse} from '../../common/http-common';

export const fetchOrganizationByCode = createAsyncThunk(
    'organization/fetchOrganizationByCode',
    async (organizationCode: string, {rejectWithValue, getState}) => {
        try {
            const res = await get(organizationCode);

            ensureSuccessResponse(res);

            return res.data;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const addOrganization = createAsyncThunk(
    'organization/addOrganization',
    async (organization: IOrganization, {rejectWithValue}) => {
        try {
            let error = null;
            let response = null;

            await add(organization)
                .then(ensureSuccessResponse)
                .then((res) => {
                    response = res.data;
                })
                .catch((err) => {
                    error = err.response.data;
                });

            if (error) {
                return rejectWithValue(error);
            }

            return response;
        } catch (err: any) {
            return rejectWithValue(err);
        }
    }
);

export const patchOrganization = createAsyncThunk(
    'organization/patchOrganization',
    async (
        patchData: {orgCode: string; patchObject: any},
        {rejectWithValue}
    ) => {
        const {orgCode, patchObject} = patchData;

        try {
            let error = null;
            let response = null;

            await patch(orgCode, patchObject)
                .then(ensureSuccessResponse)
                .then((res) => {
                    response = res.data;
                })
                .catch((err) => {
                    error = err.response.data;
                });

            if (error) {
                return rejectWithValue(error);
            }

            return response;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const organizationsSlice = createSlice({
    name: 'organizations',
    initialState: {
        organization: {
            name: '',
            description: '',
            supportRequestReference: '',
            supportArrangementType: '',
            domains: [],
            region: '',
            country: ''
        } as IOrganization,
        initialOrganization: null,
        loading: false,
        error: '' as SerializedError,
        errors: [],
        notification: null as any
    },
    reducers: {
        setOrganization: (state, action) => {
            state.organization = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationByCode.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
                state.initialOrganization = action.payload;
            })
            .addCase(fetchOrganizationByCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
                state.notification = {
                    text: action.error,
                    severity: 'error'
                };
            })
            .addCase(fetchOrganizationByCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(addOrganization.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = {
                    text: 'Successfully saved organization',
                    severity: 'success'
                };
            })
            .addCase(addOrganization.rejected, (state, action) => {
                state.loading = false;
                const {Errors} = action.payload as any;

                state.errors = Errors;
            })
            .addCase(addOrganization.pending, (state) => {
                state.loading = true;
            })
            .addCase(patchOrganization.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = {
                    text: 'Successfully saved organization',
                    severity: 'success'
                };
            })
            .addCase(patchOrganization.rejected, (state, action) => {
                state.loading = false;
                const {Errors} = action.payload as any;

                state.errors = Errors;
            })
            .addCase(patchOrganization.pending, (state) => {
                state.loading = true;
            });
    }
});

export const getOrganization = (state: RootState) => state.organization;

export const {setOrganization, setNotification} = organizationsSlice.actions;

export default organizationsSlice.reducer;
