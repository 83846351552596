import {useEffect, useState, useRef} from 'react';
import {
    IDataResult,
    IDataState,
    Loader,
    SupportedEntities
} from '../grid/dataLoader';
import {AsyncDropDownColumnMenu} from '../grid/asyncDropDownFilter';
import {TextColumnMenu} from '../grid/textFilter';
import getHttpClient, {
    ensureAcceptedResponse,
    ensureSuccessResponse
} from '../../common/http-common';
import {NavLink} from 'react-router-dom';
import {OrganizationNames} from '../../common/data/organizations';
import {TenantNames} from '../../common/data/tenants';
import {convertDateToLocale} from '../../common/utils';
import {
    Dropdown,
    HeaderField,
    Table,
    TableBody,
    TableField,
    TableHeader,
    TableRow,
    Textfield,
    Typography,
    UpIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogAction,
    Button,
    IconButton,
    AlertBar
} from '@panviva/panviva-react-ui';
import {DateColumnMenu} from '../grid/dateFilter';
import '../grid/gridStyles.scss';
import {validateUserAccess} from '../../common/utils/validateAccess';
import { AlertColor } from '../../common/types';
import moment from 'moment';
import '../../style/App.scss';
import ColumnSelector from '../grid/columnSelector';

enum GridColumns {
    Id = 'id',
    FullName = 'fullName',
    UserName = 'username',
    Organizations = 'organization',
    Tenants = 'tenant',
    OrganizationCodes = 'organizationCodes',
    TenantCodes = 'tenantCodes',
    EmailAddress = 'emailAddress',
    IsActive = 'isActive',
    LastUpdated = 'updatedDateTime'
}

const GridFields = {
    [GridColumns.Id]: 'id',
    [GridColumns.FullName]: 'fullName',
    [GridColumns.UserName]: 'username',
    [GridColumns.Organizations]: 'organizations',
    [GridColumns.Tenants]: 'tenants',
    [GridColumns.OrganizationCodes]: 'organizationCodes',
    [GridColumns.TenantCodes]: 'tenantCodes',
    [GridColumns.EmailAddress]: 'emailAddress',
    [GridColumns.IsActive]: 'isActive',
    [GridColumns.LastUpdated]: 'updatedDateTime'
};

const GridTitles = {
    [GridColumns.FullName]: 'Full Name',
    [GridColumns.UserName]: 'User Name',
    [GridColumns.Organizations]: 'Organizations',
    [GridColumns.Tenants]: 'Tenants',
    [GridColumns.EmailAddress]: 'Email',
    [GridColumns.IsActive]: 'Status',
    [GridColumns.LastUpdated]: 'Last Updated'
};

const GridTitlesArray = [
    {name: 'Full Name', checked: true},
    {name: 'User Name', checked: true},
    {name: 'Organizations', checked: true},
    {name: 'Tenants', checked: true},
    {name: 'Email', checked: true},
    {name: 'Status', checked: true},
    {name: 'Last Updated', checked: true},
];

type IOrganization = {
    organizationId: number;
    code: string;
    name: string;
    description: string;
};

type ITenant = {
    organizationId: number;
    code: string;
    name: string;
    description: string;
};

enum ResponseFields {
    Id = 'id',
    FullName = 'fullName',
    UserName = 'username',
    EmailAddress = 'emailAddress',
    UpdatedDateTime = 'updatedDateTime',
    IsActive = 'isActive',
    Organizations = 'organizations',
    Tenants = 'tenants'
}

interface IUser {
    [ResponseFields.Id]: string;
    [ResponseFields.FullName]: string;
    [ResponseFields.UserName]: string;
    [ResponseFields.EmailAddress]: string;
    [ResponseFields.IsActive]: boolean;
    [ResponseFields.UpdatedDateTime]: string;
    [ResponseFields.Organizations]: Array<IOrganization>;
    [ResponseFields.Tenants]: Array<ITenant>;
}

const transformData = (data: IUser[]) => {
    return data.map((item) => ({
        [GridFields[GridColumns.Id]]: item[ResponseFields.Id],
        [GridFields[GridColumns.FullName]]: item[ResponseFields.FullName],
        [GridFields[GridColumns.EmailAddress]]:
            item[ResponseFields.EmailAddress],
        [GridFields[GridColumns.LastUpdated]]:
            item[ResponseFields.UpdatedDateTime],
        [GridFields[GridColumns.UserName]]: item[ResponseFields.UserName],
        [GridFields[GridColumns.IsActive]]: item[ResponseFields.IsActive]
            ? 'Enabled'
            : 'Disabled',
        [GridFields[GridColumns.Organizations]]: item[
            ResponseFields.Organizations
        ]
            ?.map((o) => o.name)
            .join(', '),
        [GridFields[GridColumns.Tenants]]: item[ResponseFields.Tenants]
            ?.map((o) => o.name)
            .join(', '),
        [GridFields[GridColumns.OrganizationCodes]]: item[
            ResponseFields.Organizations
        ]
            ?.map((o) => o.code),
        [GridFields[GridColumns.TenantCodes]]: item[ResponseFields.Tenants]
            ?.map((o) => o.code)
    }));
};

export const UserGrid = (): JSX.Element => {
    const loader = useRef<React.ElementRef<typeof Loader>>(null);
    const [currentFilterQuery, setCurrentFilterQuery] = useState('');
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [notification, setNotification] = useState<{
        text: string;
        severity: AlertColor;
    }>();
    const [users, setUsers] = useState<IDataResult>({data: [], total: 0});
    const [dataState, setDataState] = useState<IDataState>({
        take: 50,
        skip: 0,
        page: 1,
        filter: {logic: 'and', filters: []}
    });

    const [searchString, setSearchString] = useState('');
    const [appliedSearchString, setAppliedSearchString] = useState<
        string | undefined
    >(undefined);

    const [selected, setSelected] = useState<any[]>([]);
    const columnSelectionState = JSON.parse(localStorage.getItem('tableColumnSelection') || JSON.stringify(GridTitlesArray));


    const handleBulkExport = () => {
        (async () => {
            await getHttpClient()
                .get(`user/export${currentFilterQuery}`)
                .then(ensureSuccessResponse)
                .then((response) => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'users_list.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    setNotification({
                        text: 'downloaded user list successfully',
                        severity: 'success'
                    });
                })
                .catch((err) => {
                    setNotification({
                        text: 'Unable to generate the CSV file, possibly due to a server error or file corruption. Please try again later. If the problem persists, contact support.',
                        severity: 'error'
                    });
                });
        })();
    };

    const handleDelete = () => {
        const selectedUserCodes: string[] = [];
        selected.forEach((user) => {
            user.organizationCodes
                .forEach((code: string) => selectedUserCodes.push(code.trim()));
            user.tenantCodes
                .forEach((code: string) => selectedUserCodes.push(code.trim()));
        });
        const isDeletionAllowed = validateUserAccess(
            'delete',
            selectedUserCodes
        );
        if (isDeletionAllowed) {
            if (selected.length === 1) {
                (async () => {
                    await getHttpClient()
                        .delete(`user/${selected[0].id}`)
                        .then(ensureSuccessResponse)
                        .then(() => {
                            setOpenDeleteDialog(false);
                            setSelected([]);
                            setNotification({
                                text: 'Deleted user',
                                severity: 'information'
                            });

                            if (loader.current) {
                                loader.current.refreshData();
                            }
                        })
                        .catch((err) => {
                            setOpenDeleteDialog(false);
                            setNotification({
                                text: 'Could not delete user',
                                severity: 'error'
                            });
                        });
                })();
            } else {
                const userIds = selected
                    .map((user) => user.id)
                    .join('&userIds=')
                    .toString();
                (async () => {
                    await getHttpClient()
                        .delete(`user?userIds=${userIds}`)
                        .then(ensureSuccessResponse)
                        .then(() => {
                            setOpenDeleteDialog(false);
                            setSelected([]);
                            setNotification({
                                text: 'Deleted user',
                                severity: 'information'
                            });

                            if (loader.current) {
                                loader.current.refreshData();
                            }
                        })
                        .catch((err) => {
                            setOpenDeleteDialog(false);
                            setNotification({
                                text: 'Could not delete user',
                                severity: 'error'
                            });
                        });
                })();
            }
        } else {
            setOpenDeleteDialog(false);
            setNotification({
                text: 'Could not delete user, please check your permissions',
                severity: 'error'
            });
        }
    };

    const initCustomAttribute = () => {
        const table = document.getElementById('user-listing-table');
        if (table) {
          const cells = [].slice.call(table.querySelectorAll('th, td'));
          cells.filter(function (cell: any) {
            // skip custom columns
            return cell.getAttribute('data-column-type') !== "custom-menu";
          }).forEach(function (cell: any, index: number) {
            const columnIndex = index % Object.keys(GridTitles).length;
            cell.setAttribute('data-column-index', columnIndex);
            // check the existing state in localstorage and set show/hide when initialise the page
            const checked = JSON.parse(localStorage.getItem('tableColumnSelection') || JSON.stringify(columnSelectionState))[columnIndex]?.checked;
            cell.style.display = checked ? '' : 'none';
          });
        }
    }

    const handleColumnSelection = (event: any, columnIndex: number, updatedState:any[]) => {
        const table = document.getElementById('user-listing-table');
        if (table) {
            const cells = [].slice.call(table.querySelectorAll('th, td'));
            cells.filter(function (cell: any) {
              return cell.getAttribute('data-column-index') === columnIndex + '';
            }).forEach(function (cell: any) {
              cell.style.display = event.target.checked ? '' : 'none';
            });
          localStorage.setItem('tableColumnSelection', JSON.stringify(updatedState));
        }
    }

    useEffect(() => {
        setSelected([]);
        initCustomAttribute();
    }, [users?.data]);

    const dataReceived = (usersResult: IDataResult) => {
        setUsers(usersResult);
    };

    const closeNotification = () => {
        setNotification(undefined);
    };

    const filterChanged = () => {
        setDataState({...dataState, skip: 0, page: 1});
        setAppliedSearchString(searchString);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        event.preventDefault();
        setDataState({
            ...dataState,
            take: parseInt(event.target.value, 10),
            skip: 0,
            page: 1
        });
    };

    const handleFirstPageChange = () => {
        setDataState({...dataState, skip: 0, page: 1});
    };

    const handlePrevPageChange = () => {
        setDataState({
            ...dataState,
            skip: dataState.skip - dataState.take,
            page: dataState.page - 1
        });
    };

    const handleNextPageChange = () => {
        setDataState({
            ...dataState,
            skip: dataState.skip + dataState.take,
            page: dataState.page + 1
        });
    };

    const handleLastPageChange = () => {
        setDataState({
            ...dataState,
            skip: (Math.ceil(users.total / dataState.take) - 1) * dataState.take,
            page: Math.ceil(users.total / dataState.take)
        });
    };

    const handleClick = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: any
    ) => {
        const selectedIndex = selected.findIndex(
            (user: any) => user.id === item.id
        );
        let newSelected: any[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelected = [...users.data];
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleFilterApply = (
        field: string,
        value: any,
        operator: string = 'eq',
        hasSecondFilter: boolean = false
    ) => {
        if (hasSecondFilter) {
            handleSecondFilter(field, value);
        } else {
            const newObj = {field, value, operator};
            const updatedFilters = JSON.parse(
                JSON.stringify(dataState.filter?.filters)
            );
            const index = dataState.filter.filters.findIndex(
                (obj) => obj.field === field
            );
            if (index > -1) {
                value
                    ? (updatedFilters[index].value = value)
                    : updatedFilters.splice(index, 1);
            } else {
                value && updatedFilters.push(newObj);
            }
            setDataState({
                ...dataState,
                skip: 0,
                page: 1,
                filter: {logic: 'and', filters: updatedFilters}
            });
        }
    };

    const handleSecondFilter = (field: string, value: any) => {
        const updatedFilters = JSON.parse(
            JSON.stringify(dataState.filter?.filters)
        );
        Object.keys(value).forEach((element: any) => {
            const index = dataState.filter.filters.findIndex(
                (obj) =>
                    obj.field === field && obj.operator === value[element].operator
            );
            const newObj = {
                field,
                value: value[element].value,
                operator: value[element].operator
            };
            if (index > -1) {
                value
                    ? (updatedFilters[index].value = value[element].value)
                    : updatedFilters.splice(index, 1);
            } else {
                value && updatedFilters.push(newObj);
            }
        });
        setDataState({
            ...dataState,
            skip: 0,
            page: 1,
            filter: {logic: 'and', filters: updatedFilters}
        });
    };

    const handleFilterClear = (field: string) => {
        const updatedFilters = JSON.parse(
            JSON.stringify(dataState.filter?.filters)
        );
        const filteredList = updatedFilters.filter(
            (obj: any) => obj.field !== field
        );
        setDataState({
            ...dataState,
            skip: 0,
            page: 1,
            filter: {logic: 'and', filters: filteredList}
        });
    };

    const getDialogMessage = () => {
        return selected.some((user: any) => {
            return user.tenants?.split(',').length > 1;
        })
            ? 'Are you sure you want to delete this user(s)? They are associated with multiple tenants and will be removed from all of them. This action cannot be undone.'
            : 'Are you sure you want to delete this user(s)? This action cannot be undone.';
    };

    const setFilterQuery = (query: any) => {
        setCurrentFilterQuery(query);
    };

    const isSelected = (id: number) => {
        return selected.findIndex((item: any) => item.id === id) !== -1;
    };

    return (
        <div>
            <div className="up-w-full up-flex up-bg-blue-0 header-section">
                <div className="header-menu">
                    <Typography variant="caption">
                        Select row(s) to see available actions
                    </Typography>
                    <div className="header-item">
                        <Dropdown
                            id="full-menu"
                            label="FullMenu"
                            iconType
                            style={{zIndex:"10000"}}
                        >
                            <div style={{backgroundColor:"white"}}>
                            <Button
                                variant="text"
                                label='Export All'
                                color='gray'
                                capitalize
                                title="Click to export all user records with the applied filters."
                                onClick={() => handleBulkExport()}
                            />
                            <Button
                                variant="text"
                                label='Delete'
                                color='gray'
                                capitalize
                                title={selected.filter(item => item.isActive === "Disabled").length > 0 ? "selected list contains disabled users" : selected.length <= 1 ? "Select more than 1 user(s) to perform delete." : ""}
                                disabled={selected.filter(item => item.isActive === "Disabled").length > 0 || selected.length <= 1}
                                onClick={() => {
                                    setOpenDeleteDialog(true);
                                }}
                            />
                            </div>
                        </Dropdown>
                    </div>
                    {selected.length > 1 && (
                        <div className="header-item">
                            <Typography variant="body_2">
                                {`${selected.length} Users selected`}
                            </Typography>
                        </div>
                    )}
                </div>

                <Textfield
                    label="Search"
                    icon="search"
                    value={searchString || ''}
                    style={{
                        width: 227,
                        paddingRight: 14,
                        marginBottom: 8
                    }}
                    onInput={(e: any) => {
                        setSearchString(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            filterChanged();
                        }
                    }}
                    onClickIcon={() => filterChanged()}
                />
            </div>

            <Table
                id='user-listing-table'
                className={'table-container'}
                totalCount={users.total}
                totalPages={Math.ceil(users.total / dataState.take)}
                currentPage={dataState.page}
                rowsPerPage={dataState.take}
                setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    handleChangeRowsPerPage(e)
                }
                handleFirstPage={handleFirstPageChange}
                handlePrevPage={handlePrevPageChange}
                handleNextPage={handleNextPageChange}
                handleLastPage={handleLastPageChange}
            >
                <TableHeader className="table-header">
                    <TableRow type="header">
                        <HeaderField data-column-type="custom-menu">
                            <div style={{width:"25px"}}>
                                <input
                                    type="checkbox"
                                    onChange={handleSelectAllClick}
                                    checked={
                                        users.data?.length > 0 &&
                                        selected.length === users.data.length
                                    }
                                    disabled={users.total <= 0}
                                />
                            </div>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.FullName]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.FullName]}
                                    field={GridFields[GridColumns.FullName]}
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[GridColumns.FullName]
                                        )?.value
                                    }
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.UserName]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.UserName]}
                                    field={GridFields[GridColumns.UserName]}
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[GridColumns.UserName]
                                        )?.value
                                    }
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.Organizations]}
                                <AsyncDropDownColumnMenu
                                    title={
                                        GridTitles[GridColumns.Organizations]
                                    }
                                    field={
                                        GridFields[GridColumns.Organizations]
                                    }
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[
                                                    GridColumns.Organizations
                                                ]
                                        )?.value
                                    }
                                    asyncloader={OrganizationNames}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.Tenants]}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.Tenants]}
                                    field={GridFields[GridColumns.Tenants]}
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[GridColumns.Tenants]
                                        )?.value
                                    }
                                    asyncloader={TenantNames}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.EmailAddress]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.EmailAddress]}
                                    field={GridFields[GridColumns.EmailAddress]}
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[
                                                    GridColumns.EmailAddress
                                                ]
                                        )?.value
                                    }
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.IsActive]}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.IsActive]}
                                    field={GridFields[GridColumns.IsActive]}
                                    value={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                GridFields[GridColumns.IsActive]
                                        )?.value
                                    }
                                    onFilterApply={handleFilterApply}
                                    menuOptions={['Enabled', 'Disabled']}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className="column-header">
                                {GridTitles[GridColumns.LastUpdated]}{' '}
                                <DateColumnMenu
                                    title={GridTitles[GridColumns.LastUpdated]}
                                    field={GridFields[GridColumns.LastUpdated]}
                                    firstFilterValue={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                    GridFields[
                                                        GridColumns.LastUpdated
                                                    ] && obj.operator === 'ge'
                                        )?.value
                                    }
                                    secondFilterValue={
                                        dataState.filter.filters?.find(
                                            (obj) =>
                                                obj.field ===
                                                    GridFields[
                                                        GridColumns.LastUpdated
                                                    ] && obj.operator === 'le'
                                        )?.value
                                    }
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear}
                                />
                            </span>
                        </HeaderField>
                        <HeaderField data-column-type="custom-menu">
                            <ColumnSelector columnList={columnSelectionState} onColumnSelection={handleColumnSelection}/>
                        </HeaderField>
                    </TableRow>
                </TableHeader>
                {users.total ? (
                    <TableBody>
                        {users?.data?.map((dataItem: any) => {
                            const isItemSelected = isSelected(dataItem.id);
                            return (
                                <TableRow
                                    key={dataItem[GridFields[GridColumns.Id]]}
                                >
                                    <TableField data-column-type="custom-menu">
                                        <input
                                            type="checkbox"
                                            checked={isItemSelected}
                                            onChange={(event) =>
                                                handleClick(event, dataItem)
                                            }
                                        />
                                    </TableField>
                                    <TableField>
                                        <NavLink
                                            title={
                                                dataItem[
                                                    GridFields[
                                                        GridColumns.FullName
                                                    ]
                                                ]
                                            }
                                            key={
                                                dataItem[
                                                    GridFields[GridColumns.Id]
                                                ]
                                            }
                                            to={`user/${
                                                dataItem[
                                                    GridFields[GridColumns.Id]
                                                ]
                                            }`}
                                            className="label up-body-2 table-cell-custom"
                                            style={{
                                                display: 'block',
                                                color: '#2574db',
                                                textDecoration: 'none'
                                            }}
                                        >
                                            {
                                                dataItem[
                                                    GridFields[
                                                        GridColumns.FullName
                                                    ]
                                                ]
                                            }
                                        </NavLink>
                                    </TableField>
                                    <TableField>
                                        <div className='table-cell-custom' title={dataItem[
                                                GridFields[GridColumns.UserName]
                                            ]}>{
                                            dataItem[
                                                GridFields[GridColumns.UserName]
                                            ]
                                        }
                                        </div>
                                    </TableField>
                                    <TableField>
                                        <div className='table-cell-custom' title={dataItem[
                                                GridFields[
                                                    GridColumns.Organizations
                                                ]
                                            ]}>{
                                            dataItem[
                                                GridFields[
                                                    GridColumns.Organizations
                                                ]
                                            ]
                                        }
                                        </div>
                                    </TableField>
                                    <TableField>
                                        <div className='table-cell-custom' title={dataItem[
                                                GridFields[
                                                    GridColumns.Tenants
                                                ]
                                            ]}>
                                        {
                                            dataItem[
                                                GridFields[GridColumns.Tenants]
                                            ]
                                        }
                                        </div>
                                    </TableField>
                                    <TableField>
                                        <div className='table-cell-custom' title={dataItem[
                                                GridFields[
                                                    GridColumns.EmailAddress
                                                ]
                                            ]}>{
                                            dataItem[
                                                GridFields[
                                                    GridColumns.EmailAddress
                                                ]
                                            ]
                                        }
                                        </div>
                                    </TableField>
                                    <TableField>
                                        {
                                            dataItem[
                                                GridFields[GridColumns.IsActive]
                                            ]
                                        }
                                    </TableField>
                                    <TableField>
                                        {dataItem[
                                                GridFields[
                                                    GridColumns.LastUpdated
                                                ]
                                            ] ? moment(dataItem[
                                                GridFields[
                                                    GridColumns.LastUpdated
                                                ]
                                            ]).utcOffset(0, false).format("DD/MM/YYYY hh:mm A") : ''}
                                    </TableField>
                                    <TableField data-column-type="custom-menu">
                                        <Dropdown
                                            id="single-menu"
                                            label="SingleMenu"                                            
                                            iconType
                                            menuContainerClassName="margin_left_negative"
                                            disabled={selected.length === 1 &&
                                                selected[0].id.toString() ===
                                                    dataItem[
                                                        GridFields[
                                                            GridColumns.Id
                                                        ]
                                                    ]
                                                    ? false
                                                    : true}
                                        >
                                            <div style={{backgroundColor:"white"}}>
                                                <Button
                                                    variant="text"
                                                    label='Delete'
                                                    color='gray'
                                                    capitalize
                                                    title={dataItem[
                                                        GridFields[GridColumns.IsActive]
                                                    ] === 'Disabled' ? "selected user is already disabled" : ""}
                                                    disabled={
                                                        dataItem[
                                                            GridFields[GridColumns.IsActive]
                                                        ] === 'Disabled'
                                                    }                                         
                                                    onClick={() => {
                                                        setOpenDeleteDialog(
                                                            true
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Dropdown>
                                    </TableField>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                ) : (
                    <div className="table-empty">No rows to display</div>
                )}
            </Table>

            <Loader
                ref={loader}
                dataState={dataState}
                onDataReceived={dataReceived}
                searchString={appliedSearchString}
                searchStringSupportedFields={[
                    GridFields[GridColumns.FullName],
                    GridFields[GridColumns.UserName],
                    GridFields[GridColumns.EmailAddress],
                    GridFields[GridColumns.Tenants],
                    GridFields[GridColumns.Organizations]
                ]}
                entityName={SupportedEntities.User}
                transformData={transformData}
                nestedObjectsToQuery={{
                    [GridFields[GridColumns.Organizations]]: {
                        parent: 'organizations',
                        child: 'name'
                    },
                    [GridFields[GridColumns.Tenants]]: {
                        parent: 'tenants',
                        child: 'name'
                    }
                }}
                disableActiveFilter={true}
                setFilterQuery={setFilterQuery}
            />

            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}

            <Dialog open={openDeleteDialog}>
                <DialogTitle
                    style={{display: 'flex', justifyContent: 'space-between'}}
                >
                    <Typography variant="subtitle_1">Delete User(s)</Typography>
                    <IconButton
                        icon="clear"
                        variant="secondary"
                        onClick={() => setOpenDeleteDialog(false)}
                    ></IconButton>
                </DialogTitle>
                <DialogContent>{getDialogMessage()}</DialogContent>
                <DialogAction>
                    <Button
                        variant="text"
                        capitalize={true}
                        onClick={() => setOpenDeleteDialog(false)}
                    >
                        Cancel
                    </Button>
                    &nbsp;
                    <Button
                        variant="text"
                        capitalize={true}
                        onClick={() => handleDelete()}
                    >
                        Continue
                    </Button>
                </DialogAction>
            </Dialog>
        </div>
    );
};

export default UserGrid;
