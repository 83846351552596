import getHttpClient from '../http-common';

const CountriesList = () =>
    getHttpClient()
        .get('Utility/Countries')
        .then((response) => response.data)
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });

export default CountriesList;
