import { CircleProgress } from '@panviva/panviva-react-ui';
import './common.scss';

const LoadingWBackdrop = () => {
    return (
        <div className='loader-container'>
            <div className='loader-element'
            >
                <div style={{ height: 100, width: 100 }}>
                    <CircleProgress size="contain" />
                </div>
            </div>
        </div>
    );
};

export default LoadingWBackdrop;
