import {BottomLinks, Page} from '../../components/common/page';
import {AlertBar, Button, Chip, Typography} from '@panviva/panviva-react-ui';
import {fetchTenantByCode, getTenant} from '../../features/tenants/tenantSlice';
import getHttpClient, {
    ensureAcceptedResponse,
    ensureSuccessResponse
} from '../../common/http-common';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {DetailsBlock} from '../../components/common/detailBlock';
import LoadingWBackdrop from '../../components/common/LoadingWBackdrop';
import PageNotFound from '../../components/PageNotFound';
import {postReactivateUsersInTenant} from '../../features/oktaWorkflows/oktaWorkflowService';
import {useHistory} from 'react-router-dom';
import { AlertColor } from '../../common/types';
import '../../style/App.scss';

const TenantDetails = ({match}: any) => {
    const {tenantCode} = match.params;
    const [notification, setNotification] = useState<{
        text: string;
        severity: AlertColor;
    }>();
    const dispatch = useDispatch();
    const data = useSelector(getTenant);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            await dispatch(fetchTenantByCode(tenantCode));
        })();
    }, []);

    const closeNotification = () => {
        setNotification(undefined);
    };

    return (
        <div className="up-h-full up-w-full up-bg-gray-10">
            {data.loading && <LoadingWBackdrop />}
            {data.tenant && (
                <Page title={data.tenant.name} backOption={true}>
                    <div className="up-px-7">
                        <DetailsBlock
                            title="Tenant Code"
                            data={data.tenant.code}
                        />
                        <DetailsBlock title="Name" data={data.tenant.name} />
                        <DetailsBlock
                            title="Tenant URL"
                            data={data.tenant.tenantUrl}
                        />
                        <DetailsBlock
                            title="Organization Name"
                            data={data.tenant.organizationName}
                        />
                        <DetailsBlock
                            title="Description"
                            data={data.tenant.description}
                        />
                        <DetailsBlock
                            title="Region"
                            data={data.tenant.region}
                        />
                        <DetailsBlock
                            title="Default Identity Providers"
                            data={data.tenant.defaultIdentityProvider?.name}
                        />
                        <DetailsBlock
                            title="Allowed Identity Providers"
                            data={data.tenant.allowedIdentityProviders?.map(
                                (identityProvider) => {
                                    return (
                                        <Chip
                                            label={identityProvider.name}
                                            key={
                                                identityProvider.identityProviderId
                                            }
                                        />
                                    );
                                }
                            )}
                        />
                        <DetailsBlock
                            title="Default Domain"
                            data={data.tenant.defaultDomain}
                        />
                        <DetailsBlock
                            title="Domains"
                            data={data.tenant.domains?.map((domain) => {
                                return <Chip label={domain} key={domain} />;
                            })}
                        />
                        {data.tenant.aPIDetails && (
                            <DetailsBlock
                                title="APIM"
                                data={data.tenant.aPIDetails?.join(', ')}
                            />
                        )}
                        <Typography variant="subtitle_1">
                            Old Instance
                        </Typography>
                        <DetailsBlock
                            title="Old SPS Details"
                            data={data.tenant.spsDetails}
                        />
                        <DetailsBlock
                            title="Old URL"
                            data={data.tenant.oldUrl}
                        />
                        <DetailsBlock
                            title="Old Instance Name"
                            data={data.tenant.oldInstanceName}
                        />
                    </div>
                    <BottomLinks>
                        <Button
                            label="Delete"
                            variant="outlined"
                            slot="actions"
                            className="up-mr-2"
                            onClick={() => {
                                (async () => {
                                    await postReactivateUsersInTenant(
                                        tenantCode
                                    )
                                        .then(ensureAcceptedResponse)
                                        .then(() => {
                                            setNotification({
                                                text: 'Resent Activation Email',
                                                severity: 'information'
                                            });
                                        })
                                        .catch((err) => {
                                            setNotification({
                                                text: 'Error resending reactivation email',
                                                severity: 'error'
                                            });
                                        });
                                })();
                            }}
                        >
                            Resend Activation Email
                        </Button>
                        <Button
                            variant="outlined"
                            className="up-mr-2"
                            onClick={() => {
                                (async () => {
                                    await getHttpClient()
                                        .delete(`tenant/${tenantCode}`)
                                        .then(ensureSuccessResponse)
                                        .then(() => {
                                            history.goBack();
                                        })
                                        .then(() => {
                                            setNotification({
                                                text: 'Deleted organization',
                                                severity: 'information'
                                            });
                                        })
                                        .catch((err) => {
                                            setNotification({
                                                text: 'Could not delete organiation',
                                                severity: 'error'
                                            });
                                        });
                                })();
                            }}
                        />
                        <Button
                            is-link="true"
                            label="Edit"
                            variant="outlined"
                            slot="actions"
                            href={`/tenant/edit/${tenantCode}`}
                        />
                    </BottomLinks>
                </Page>
            )}
            {data.error && <PageNotFound />}
            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};

export default TenantDetails;
