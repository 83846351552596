import { FunctionComponent, useEffect, useState } from "react";
import { Card, Popover, UpIcon } from '@panviva/panviva-react-ui';
import './gridStyles.scss';

interface ColumnSelectorProps {
    columnList: any[];
    onColumnSelection: (event:any, index:number, list:any[], selectAll?: boolean) => void;
}
 
const ColumnSelector: FunctionComponent<ColumnSelectorProps> = (props) => {

    const { columnList, onColumnSelection } = props;

    const [openSelection, setOpenSelection] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [columnListItems, setColumnListItems] = useState<any>([]);
    const [allHidden, setAllHidden]= useState(false);

    const id = openSelection ? 'popper-async-dropdown' : undefined;

    useEffect(()=>{
        setColumnListItems(columnList);
    },[columnList])

    useEffect(()=>{
        setOpenSelection(Boolean(anchorEl));
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelection = (event:any, index:number) =>{
        const updatedList = JSON.parse(JSON.stringify(columnListItems));
        updatedList[index].checked = event.target.checked;
        onColumnSelection(event, index, updatedList);
        setColumnListItems(updatedList);
        setAllHidden(updatedList?.filter((list:any) => list.checked == true).length < 1);
    }

    return ( 
    <div>            
        <span className={`selection-icon-container${openSelection ? ' icon-rotate' : ''}`} onClick={handleClick}>
            <UpIcon icon="menu-default"/>
        </span>

        <Popover className={ !allHidden ? "margin_left_negative_10" : ""} id={id} open={openSelection} anchorEl={anchorEl} onClose={() => handleClose()} anchorOrigin={{vertical:'bottom', horizontal:'left'}}>
                <Card className='selection-popup'>
                    {columnListItems?.map((column:any, index:number) =>{
                        return <div key={column.name} className="selection-checkbox">
                                    <input type="checkbox" id={column.name} value={column.checked} checked={column.checked} onChange={(event)=>handleSelection(event, index)}/>&nbsp;
                                    <label>{column.name}</label><br></br>
                                </div>
                    })}
                </Card>
        </Popover>
    
    </div>
    );
}
 
export default ColumnSelector;