/* eslint-disable @typescript-eslint/no-unused-vars */
import {ITenant, add, get, patch} from './tenantService';
import {SerializedError, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {ensureSuccessResponse} from '../../common/http-common';

export const fetchTenantByCode = createAsyncThunk(
    'tenant/fetchTenantByCode',
    async (tenantCode: string, {rejectWithValue}) => {
        try {
            const res = await get(tenantCode);

            ensureSuccessResponse(res);

            return res.data;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const addTenant = createAsyncThunk(
    'tenant/addTenant',
    async (tenant: ITenant, {rejectWithValue}) => {
        try {
            let error = null;
            let response = null;

            await add(tenant)
                .then(ensureSuccessResponse)
                .then((res) => {
                    response = res.data;
                })
                .catch((err) => {
                    error = err.response.data;
                });

            if (error) {
                return rejectWithValue(error);
            }

            return response;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);
export const patchTenant = createAsyncThunk(
    'tenant/patchTenant',
    async (
        patchData: {tenantCode: string; patchObject: any},
        {rejectWithValue}
    ) => {
        const {tenantCode, patchObject} = patchData;

        try {
            let error = null;
            let response = null;

            await await patch(tenantCode, patchObject)
                .then(ensureSuccessResponse)
                .then((res) => {
                    response = res.data;
                })
                .catch((err) => {
                    error = err.response.data;
                });

            if (error) {
                return rejectWithValue(error);
            }

            return response;
        } catch (err) {
            rejectWithValue(err);
        }
    }
);

export const tenantSlice = createSlice({
    name: 'tenants',
    initialState: {
        tenant: {
            code: '',
            name: '',
            description: '',
            organizationName: '',
            spsDetails: '',
            oldUrl: '',
            oldInstanceName: '',
            aPIDetails: [],
            region: '',
            tenantUrl: '',
            version: '',
            domains: [],
            defaultDomain: ''
        } as ITenant,
        initialTenant: null,
        loading: false,
        error: '' as SerializedError,
        errors: [],
        notification: null as any
    },
    reducers: {
        setTenant: (state, action) => {
            state.tenant = action.payload;
        },
        setNotification: (state, action) => {
            state.notification = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTenantByCode.fulfilled, (state, action) => {
                state.loading = false;
                state.tenant = action.payload;
                state.initialTenant = action.payload;
            })
            .addCase(fetchTenantByCode.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error;
            })
            .addCase(fetchTenantByCode.pending, (state) => {
                state.loading = true;
            })
            .addCase(addTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = {
                    text: 'Successfully saved tenant',
                    severity: 'success'
                };
            })
            .addCase(addTenant.rejected, (state, action) => {
                state.loading = false;

                state.notification = {
                    text: 'Could not save tenant',
                    severity: 'error'
                };
                const {Errors} = action.payload as any;

                state.errors = Errors;
            })
            .addCase(addTenant.pending, (state) => {
                state.loading = true;
            })
            .addCase(patchTenant.fulfilled, (state, action) => {
                state.loading = false;
                state.notification = {
                    text: 'Successfully saved tenant',
                    severity: 'success'
                };
            })
            .addCase(patchTenant.rejected, (state, action) => {
                state.loading = false;

                state.notification = {
                    text: 'Could not save tenant',
                    severity: 'error'
                };
                const {Errors} = action.payload as any;

                state.errors = Errors;
            })
            .addCase(patchTenant.pending, (state) => {
                state.loading = true;
            });
    }
});

export const getTenant = (state: RootState) => state.tenant;

export const {setTenant, setNotification} = tenantSlice.actions;

export default tenantSlice.reducer;
