import {Typography} from '@panviva/panviva-react-ui';

export const DetailsBlock = (props: {title: string; data: any}) => {
    return (
        <>
            {props.data && (
                <Typography
                    variant="caption"
                    className=" up-mb-2 text-color-50"
                    style={{color: '#838fa0'}}
                >
                    {props.title || <br />}
                    <br />
                    <Typography variant="body_1" className=" up-mb-3">
                        {props.data}
                    </Typography>
                </Typography>
            )}
        </>
    );
};
