/* eslint-disable no-console */
import './style/App.scss';
import {AuthState, OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {LoginCallback, SecureRoute, Security} from '@okta/okta-react';
import {Route, Switch, useHistory} from 'react-router-dom';
import config from './app/config';

import Header from './components/Header';
import Organization from './pages/organizations/OrganizationDetails';
import OrganizationCreate from './pages/organizations/OrganizationCreate';
import Organizations from './pages/organizations/OrganizationList';
import PageUnderConstruction from './components/PageUnderConstruction';
import Profile from './pages/Profile';
import SideNav from './components/SideNav';
import Tenant from './pages/tenants/TenantDetails';
import TenantCreate from './pages/tenants/TenantCreate';
import Tenants from './pages/tenants/TenantList';
import User from './pages/users/UserDetails';
import Users from './pages/users';
import {useState} from 'react';

const oktaAuth = new OktaAuth(config.okta);

oktaAuth.authStateManager.subscribe((authState: AuthState) => {
    localStorage.setItem('accessToken', `${authState.accessToken?.value}`);
    const userDetails:any = authState.accessToken?.value && JSON.parse(atob(authState.accessToken?.value.split('.')[1]));
    if( userDetails ){
        const isSuperAdmin = userDetails.panviva_ecosystem_claimant?.toLowerCase() === 'administrator';
        isSuperAdmin && localStorage.setItem('userRole','superAdmin');       
        userDetails.permissions?.length > 0 && localStorage.setItem('userPermissions', JSON.stringify(userDetails.permissions));
    }
});

function App() {
    const [menuOpen, setMenuOpen] = useState(false);

    const history = useHistory();
    const restoreOriginalUri = async (
        _oktaAuth: OktaAuth,
        originalUri: string
    ) => {
        history.replace(
            toRelativeUrl(originalUri || '/', window.location.origin)
        );
    };

    const handleSideNavToggle = () => {
        setMenuOpen((prev) => !prev);
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <icon-sprite />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    height: '100vh',
                    maxHeight: '100%',
                    width: '100vw',
                    overflow: 'auto'
                }}
            >
                <Header
                    menuOpen={menuOpen}
                    setMenuOpen={() => handleSideNavToggle()}
                />
                <div
                    id="main-content"
                    className='up-h-full up-w-full'
                    style={{display: 'flex'}}
                >
                    <div
                        style={{
                            width: 56,
                            float: 'left',
                            zIndex: 10000,
                            position: 'relative',
                            height: '-webkit-fill-available'
                        }}
                    >
                        <SideNav
                            menuOpen={menuOpen}
                            setMenuOpen={() => handleSideNavToggle()}
                        />
                    </div>
                    <div
                        style={{
                            height: '-webkit-fill-available',
                            flexGrow: 1,
                            width: '95%',
                            display: 'flex',
                            justifyContent: 'stretch',
                            alignItems: 'stretch'
                        }}
                    >
                        <Switch>
                            <Route
                                path="/login/callback"
                                component={LoginCallback}
                            />
                            <SecureRoute
                                path="/users"
                                exact
                                component={Users}
                            />
                            <SecureRoute
                                path="/user/:userId"
                                exact
                                component={User}
                            />
                            <SecureRoute
                                path="/profile"
                                exact
                                component={Profile}
                            />
                            <SecureRoute
                                path="/organizations"
                                component={Organizations}
                            />
                            <SecureRoute
                                path="/organization/details/:organizationCode"
                                component={Organization}
                            />
                            <SecureRoute
                                path="/organization/create"
                                component={OrganizationCreate}
                            />
                            <SecureRoute
                                path="/organization/edit/:organizationCode"
                                component={OrganizationCreate}
                            />
                            <SecureRoute path="/tenants" component={Tenants} />
                            <SecureRoute
                                path="/tenant/details/:tenantCode"
                                component={Tenant}
                            />
                            <SecureRoute
                                path="/tenant/create"
                                component={TenantCreate}
                            />
                            <SecureRoute
                                path="/tenant/edit/:tenantCode"
                                component={TenantCreate}
                            />
                            <Route component={PageUnderConstruction} />
                        </Switch>
                    </div>
                </div>
            </div>
        </Security>
    );
}

export default App;
