/* eslint-disable no-undef */

const API_BASE_URL = '/api';
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const ISSUER = process.env.REACT_APP_OKTA_ISSUER;
const OKTA_TESTING_DISABLEHTTPSCHECK = false;
const BASENAME = process.env.PUBLIC_URL || '';
const SCOPES = ['openid','email','profile','panviva.ecosystem'];
const REDIRECT_URI = `${window.location.origin}${BASENAME}/login/callback`;

export default {
    apiBaseUrl: API_BASE_URL,
    okta: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: SCOPES,
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK
    },
    app: {
        basename: BASENAME
    }
};
