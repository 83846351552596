import {RootState} from '../../app/store';
import UserService from './userService';

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const retrieveUsers = createAsyncThunk(
    'user/retrieve',
    async (page: number) => {
        const pageSize = 50;
        const res = await UserService.getPage(pageSize, pageSize * (page - 1));

        return res.data.value;
    }
);

export const retrieveUser = createAsyncThunk(
    'user/retrieveOne',
    async (userId: number) => {
        const res = await UserService.getUser(userId);

        return res.data;
    }
);

export const usersSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        user: null,
        loading: true,
        pageSize: 50
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(retrieveUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.users = action.payload;
        });

        builder.addCase(retrieveUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
        });
    }
});

export const getUsers = (state: RootState) => state.users;
export default usersSlice.reducer;
