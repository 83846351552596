import { Select } from "@panviva/panviva-react-ui";
import { useEffect, useState } from "react";
import './common.scss';

const DropdownSelect = (props: any) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>(props.options || []);
    const [selected, setSelected] = useState<any[]>([]);
    const loading = open && options.length === 0;
    
    useEffect(()=>{
        setOptions(props.options);
    },[props.options])

    function handleClickSelection (option:any) {
        setSelected([option.name || option]);
        props.onInputChange(event, option);
    }

    return (
        <div>
            <Select loading={loading}
                    label={`${props.title}${(props.required && ' *') || ''}`} 
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    dropdownClass='dropdown_content'
                    dropdownStyle={props.dropdownStyle}
                    selected={selected}
                    style={props.style}
                    >
                {
                    options?.map((option:any)=>{
                    return<div key={option.name || option} className='menu_item' onClick={()=>handleClickSelection(option)}>
                    <label>{option.name || option}</label>
                     </div>
                    })               
                }
            </Select>
        </div>
    );
};

export default DropdownSelect;
