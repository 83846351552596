import getHttpClient from '../../common/http-common';

const getAll = () => {
    return getHttpClient().get('/user?$count=true');
};

const getPage = (top: number, skip: number) => {
    return getHttpClient().get(`/user?$count=true&$top=${top}&$skip=${skip}`);
};

const getUser = (userId: number) => {
    return getHttpClient().get(`/user/${userId}`);
};

const userservice = {
    getAll,
    getUser,
    getPage
};

export default userservice;
