import LoadingWBackdrop from '../common/LoadingWBackdrop';
import React from 'react';
import buildQuery from 'odata-query';

import getHttpClient from '../../common/http-common';
import { Snackbar } from '@panviva/panviva-react-ui';

export enum SupportedEntities {
    Organization = 'Organization',
    User = 'User',
    Tenant = 'Tenant'
}

export interface IRef {
    refreshData: () => void;
}

export interface IFilterArrayState {
    field: string,
    operator: string,
    value: any
}

export interface IFilterState {
    logic: string,
    filters: Array<IFilterArrayState>
}

export interface IDataState {
    skip: number;
    take: number;
    page: number;
    filter: IFilterState;
}

export interface IDataResult {
    data: any[];
    total: number;
}

interface ILoaderProps {
    dataState: IDataState;
    searchString: string | undefined;
    searchStringSupportedFields: string[];
    entityName: SupportedEntities;
    nestedObjectsToQuery?: any;
    stringArrayToQuery?: string[];
    disableActiveFilter?: boolean;
    onDataReceived: (records: IDataResult) => void;
    transformData?: (records: any | undefined) => any;
    setFilterQuery?: (query: string) => void;
}

export const Loader = React.forwardRef<IRef | null, ILoaderProps>(
    (props: ILoaderProps, ref) => {
        const baseUrl = props.entityName;
        const lastSuccess = React.useRef<string>('');
        const pending = React.useRef<string>('');
        const [reload, setReload] = React.useState(false);
        const [notification, setNotification] = React.useState('');

        React.useImperativeHandle(ref, () => ({
            refreshData() {
                setReload(true);
                requestDataIfNeeded();
            }
        }));

        const convertToFilterObject: any = (filter: any) => {
            if (filter.logic) {
                if (filter.filters && filter.filters.length === 1) {
                    return convertToFilterObject(filter.filters[0]);
                }

                return {
                    [filter.logic]: filter.filters.map(convertToFilterObject)
                };
            }

            if (
                props.nestedObjectsToQuery &&
                props.nestedObjectsToQuery[filter.field]
            ) {
                return {
                    [props.nestedObjectsToQuery[filter.field].parent]: {
                        any: {
                            [props.nestedObjectsToQuery[filter.field].child]: {
                                [filter.operator]: filter.value
                            }
                        }
                    }
                };
            }

            if (
                props.stringArrayToQuery &&
                props.stringArrayToQuery.includes(filter.field)
            ) {
                return `${filter.field}/any(d:contains(d,'${filter.value}'))`;
            }

            if (filter.field === "updatedDateTime") {
                return {
                    [filter.field]: {
                        [filter.operator]: {
                            type: 'raw',
                            value: filter.operator === "ge" ? `${filter.value}:00Z` : `${filter.value}:00Z`
                        }
                    }
                };
            }

             // new logic for isActive filter
             if(filter.field === 'isActive' &&  filter.value?.length > 0){
                return {
                    [filter.field]: {
                        [filter.operator]: filter.value.map((item:string) => item === 'Enabled' ? true : false)
                    }
                };
             }

            return {
                [`tolower(${filter.field})`]: {
                    [filter.operator]: filter.value
                }
            };
        };

        const requestDataIfNeeded = () => {
            const dataState = JSON.parse(JSON.stringify(props.dataState));

            if (props.searchString && props.searchString !== '') {
                if (dataState.filter) {
                    dataState.filter = {
                        logic: 'and',
                        filters: [
                            {
                                logic: 'or',
                                filters: props.searchStringSupportedFields.map(
                                    (field) => ({
                                        field,
                                        value: props.searchString?.toLowerCase(),
                                        operator: 'contains'
                                    })
                                )
                            },
                            dataState.filter
                        ]
                    };
                } else {
                    dataState.filter = {
                        logic: 'or',
                        filters: props.searchStringSupportedFields.map(
                            (field) => ({
                                field,
                                value: props.searchString?.toLowerCase(),
                                operator: 'contains'
                            })
                        )
                    };
                }
            }

            if (!props.disableActiveFilter) {
                if (!dataState.filter) {
                    dataState.filter = {
                        field: 'isActive',
                        value: true,
                        operator: 'eq'
                    };
                } else {
                    dataState.filter = {
                        logic: 'and',
                        filters: [
                            {
                                field: 'isActive',
                                value: true,
                                operator: 'eq'
                            },
                            dataState.filter
                        ]
                    };
                }
            } else if (!dataState.filter) {
                dataState.filter = {};
            }

            const exampleObj = convertToFilterObject(dataState.filter);

            dataState.filter = exampleObj;
            const query = buildQuery({
                ...dataState,
                top: dataState.take,
                count: true
            });

            //build query with only filters and remove count & top - for export end point
            const filterQuery = buildQuery({
                ...dataState,
                skip: 0
            });
            props.setFilterQuery && props.setFilterQuery(filterQuery);

            if (!reload && (pending.current || query === lastSuccess.current)) {
                return;
            }

            setReload(false);
            pending.current = query;
            getHttpClient()
                .get(`${baseUrl}${pending.current}`)
                .then((response) => response.data)
                .then((json) => {
                    lastSuccess.current = pending.current;
                    pending.current = '';

                    if (query === lastSuccess.current) {
                        props.onDataReceived.call(undefined, {
                            data:
                                props.transformData === undefined
                                    ? json.value
                                    : props.transformData(json.values),
                            total: json.count
                        });
                    } else {
                        requestDataIfNeeded();
                    }
                })
                .catch((error: Error) => {
                    setNotification(error.message || 'Internal Server Error')
                });
        };

        requestDataIfNeeded();

        return notification ?
            <Snackbar
                icon='warning'
                status='error'
                style={{ position: 'absolute', bottom: 0 }}
                onClose={() => { setNotification(''); pending.current = ''; }}>
                <span>{notification}</span>
            </Snackbar>
            : pending.current ? <LoadingWBackdrop /> : null
    }
);

Loader.displayName = 'Loader';
