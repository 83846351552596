import getHttpClient from '../http-common';

export const Organizations = () =>
    getHttpClient()
        .get('Organization?&$filter=isActive%20eq%20true&$select=code,name')
        .then((response) => response.data)
        .then((json) => json.values)
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });

export const OrganizationNames = () =>
    Organizations()
        .then((data) => data.map((item: any) => item.name))
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });
