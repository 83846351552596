import {
    Button,
    HeaderField,
    Table,
    TableBody,
    TableField,
    TableHeader,
    TableRow,
    Typography
} from '@panviva/panviva-react-ui';
import {useEffect, useState} from 'react';
import {Page} from '../components/common/page';
import {UserClaims} from '@okta/okta-auth-js';
import {useHistory} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

const Profile = () => {
    const {authState, oktaAuth} = useOktaAuth();
    const [userInfo, setUserInfo] = useState<UserClaims>();
    const history = useHistory();

    const login = async () => {
        await oktaAuth.signInWithRedirect({
            originalUri: history.location.pathname
        });
    };
    const logout = async () => {
        await oktaAuth.signOut();
        localStorage.setItem('accessToken', '');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userPermissions');
        history.push('/');
    };

    const userText = authState.isAuthenticated ? (
        <Button onClick={logout}>Logout</Button>
    ) : (
        <Button onClick={login}>Sign In</Button>
    );

    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(undefined);
        } else {
            oktaAuth
                .getUser()
                .then((info) => {
                    setUserInfo(info);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    if (!userInfo) {
        return (
            <div>
                <Typography variant="body_2">
                    Fetching user profile...
                </Typography>
            </div>
        );
    }

    return (
        <Page title="User Profile" backOption={true}>
            <Typography variant="subtitle_2">
                My User Profile (ID Token Claims)
            </Typography>

            <Typography variant="body_1">
                Below is the information from your ID token which was obtained
                during the &nbsp;
                <a href="https://developer.okta.com/docs/guides/implement-auth-code-pkce">
                    PKCE Flow
                </a>{' '}
                and is now stored in local storage.
            </Typography>

            <Typography variant="body_1">
                This route is protected with the{' '}
                <code>&lt;SecureRoute&gt;</code> component, which will ensure
                that this page cannot be accessed until you have authenticated.
            </Typography>
            <Table>
                <TableHeader>
                    <TableRow type="header">
                        <HeaderField>Claim</HeaderField>
                        <HeaderField>Value</HeaderField>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {Object.entries(userInfo).map((claimEntry) => {
                        const claimName = claimEntry[0];
                        const claimValue = claimEntry[1];
                        const claimId = `claim-${claimName}`;

                        return (
                            <TableRow key={claimName}>
                                <TableField>{claimName}</TableField>
                                <TableField id={claimId}>
                                    {claimValue.toString()}
                                </TableField>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div style={{width: 'fit-content'}}>{userText}</div>
        </Page>
    );
};

export default Profile;
