import React, {ReactNode} from 'react';
import {TitleBar} from '@panviva/panviva-react-ui';
import {useHistory} from 'react-router-dom';

export const Page = (
    props: React.PropsWithChildren<{
        title: string;
        backOption?: boolean;
        titleBarContent?: ReactNode;
    }>
) => {
    const history = useHistory();

    return (
        <div
            className="up-bg-gray-10 Page"
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}
        >
            <TitleBar
                className='up-w-full'
                title={props.title}
                backOption={props.backOption}
                onBack={history.goBack}
            >
                {props.titleBarContent}
            </TitleBar>
            <div
                style={{
                    height: '100%',
                    maxWidth: 960,
                    width: '100%',
                    overflow: 'hidden'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'auto',
                        backgroundColor: 'white'
                    }}
                >
                    {props.children}
                </div>
            </div>
        </div>
    );
};

export const BottomLinks = (props: React.PropsWithChildren<any>) => {
    return (
        <div
            className="up-text-right up-w-full up-px-7 up-py-2 up-border-tertiary"
            style={{
                bottom: 0,
                paddingLeft: 'inherit',
                paddingRight: 'inherit',
                left: 0,
                right: 0,
                borderTop: '1px solid'
            }}
        >
            {props.children}
        </div>
    );
};
