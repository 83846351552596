export const validateUserAccess = (operation:string, codes:string[]) =>{
    const userRole = getUserRole();
    if(userRole === 'superAdmin'){
        return true;
    }
    else{
        const allAllowedCodes:string[] = [];
        const userPermissions = getUserPermissions();
        const position = getPosition(operation);
        Array.isArray(userPermissions) && userPermissions.forEach((permission:any)=>{
            (permission.orgCode && permission.users.at(position) === '1' ) && allAllowedCodes.push(permission.orgCode);
            (permission.tenantCode && permission.users.at(position) === '1' ) && allAllowedCodes.push(permission.tenantCode);
        });
        
        //check all incoming codes are part of allowedCodes
        const isAllCodesPresent = codes.every((code) => allAllowedCodes.indexOf(code) > -1);
        return isAllCodesPresent;
    }
}

export const getUserRole = () => localStorage.getItem('userRole');

export const getUserPermissions = () => JSON.parse(localStorage.getItem('userPermissions') || '{}');

const getPosition = (operation:string) => {
    let position = -1;
    switch(operation){
        case 'create':{
            return position = 0;
        }
        case 'read':{
            return position = 1;
        }
        case 'update':{
            return position = 2;
        }
        case 'delete':{
            return position = 3;
        }
        default:{ return position = -1}
    }
}