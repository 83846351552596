import getHttpClient from '../../common/http-common';

export interface IIdentifierProvider {
    name: string;
    identityProviderId: string;
    id: number;
}

export interface ITenant {
    code: string;
    name: string;
    description: string;
    organizationName: string;
    spsDetails: string;
    oldUrl: string;
    oldInstanceName: string;
    aPIDetails?: string[];
    region: string;
    tenantUrl: string;
    version: string;
    domains?: string[];
    defaultIdentityProvider?: IIdentifierProvider;
    allowedIdentityProviders?: IIdentifierProvider[];
    defaultDomain: string;
}

export const get = (tenantCode: string) =>
    getHttpClient().get(`tenant/${tenantCode}`);
export const add = (tenant: ITenant) => getHttpClient().post('tenant', tenant);
export const patch = (tenantCode: string, patchObject: any) =>
    getHttpClient().patch(`tenant/${tenantCode}`, patchObject);
