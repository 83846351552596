import getHttpClient from '../http-common';

export const IdentityProviders = () =>
    getHttpClient()
        .get('OktaIdentityProvider?$filter=isActive%20eq%20true')
        .then((response) => response.data)
        .then((json) => json.values)
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });

export const IdentityProviderNames = () =>
    IdentityProviders().then((data) => data?.map((item: any) => item.name));
