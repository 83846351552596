import { Button, Card, IconButton, Popover, Typography } from '@panviva/panviva-react-ui';
import { Textfield } from '@panviva/panviva-react-ui';
import { useEffect, useState} from 'react';
import './gridStyles.scss';

export const TextColumnMenu = (props: any) => {

    const { title, field, value, onFilterApply, onClear } = props;    
    const [textValue, setTextValue] = useState(value);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState(false);
    const id = open ? 'popper-text' : undefined;

    useEffect(()=>{
        setOpen(Boolean(anchorEl))
    },[anchorEl])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget?.parentElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onTextChange = (event: any) => {
        setTextValue(event.target.value);
    }

    const onButtonClick = (buttonName: string) => {
        if (buttonName === 'apply') {
            onFilterApply(field, textValue.toLowerCase(), "contains")
        } else {
            onClear(field);
            setTextValue('');
        }
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton icon={value ? "filter-active" : "filter-inactive"} onClick={handleClick}/>

            <Popover id={id} open={open} anchorEl={anchorEl} onClose={() => handleClose()} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Card style={{ display: 'flex' }} elevated noOutline>
                    <Typography
                        style={{ marginBottom: '10%', fontWeight: 'bold' }}
                        variant="body_2"
                    >
                        {title}
                    </Typography>
                    <Textfield
                        value={textValue}
                        id={title}
                        name={title}
                        label={`Enter ${title}`}
                        autoFocus={true}
                        style={{ display: 'flex', minWidth: 200, fontSize: 12 }}
                        onInput={onTextChange}
                    />
                    <div className='filter-button'>
                        <Button variant='text' onClick={() => onButtonClick('clear')}>clear</Button>&nbsp;
                        <Button variant='text' onClick={() => onButtonClick('apply')}>apply</Button>
                    </div>
                </Card>
            </Popover>
        </>
    );
};
