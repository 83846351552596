// import {NavLink} from 'react-router-dom';
import { Button } from '@panviva/panviva-react-ui';
import { OrganizationGrid } from '../../components/organizations/organizationListGrid';
import { TitleBar } from '@panviva/panviva-react-ui';

const Organizations = () => {
    return (
        <div className="up-h-full" style={{ width: '100%' }}>
            <div
                className="up-bg-gray-10 up-items-center"
            >
                <TitleBar title="Organizations" backOption={false}>
                    <Button
                        icon="add"
                        label="Add"
                        variant="outlined"
                        href="organization/create"
                    />
                </TitleBar>
                <OrganizationGrid />
            </div>
        </div>
    );
};

export default Organizations;
