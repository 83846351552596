import { Select } from '@panviva/panviva-react-ui';
import {useEffect, useState} from 'react';
import './common.scss';

const AsynchronousDropdownSelect = (props: any) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const [selected, setSelected] = useState<any[]>([]);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await props.asyncloader().then((data: any) => {
                if (active) {
                    setOptions(data);
                }
            });
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    function handleClickSelection (option:any) {
        setSelected([option.name || option]);
        props.onChange(event, option);
    }

    return (
        <div>            
            <Select loading={loading}
                    label={`${props.title}${(props.required && ' *') || ''}`} 
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    dropdownClass='dropdown_content'
                    dropdownStyle={props.dropdownStyle}
                    selected={selected}
                    style={props.style}
                    >
                {
                    options?.map((option:any)=>{
                    return<div key={option.name || option} className='menu_item' onClick={()=>handleClickSelection(option)}>
                    <label>{option.name || option}</label>
                     </div>
                    })               
                }
            </Select>
        </div>
    );
};

export default AsynchronousDropdownSelect;
