import getHttpClient from '../../common/http-common';

export interface IIdentifierProvider {
    name: string;
    identityProviderId: string;
    id: number;
}

export interface IOrganization {
    code?: string;
    name?: string;
    description?: string;
    supportRequestReference?: string;
    supportArrangementType?: string;
    domains: string[];
    region?: string;
    country?: string;
    defaultIdentityProvider?: IIdentifierProvider;
    allowedIdentityProviders?: IIdentifierProvider[];
}

export const get = (organizationCode: string) => {
    return getHttpClient().get(`organization/${organizationCode}`);
};

export const add = (organization: IOrganization) => {
    return getHttpClient().post('organization', organization);
};

export const patch = (orgCode: string, patchObject: any) => {
    return getHttpClient().patch(`organization/${orgCode}`, patchObject);
};
