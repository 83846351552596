import {Button, TitleBar} from '@panviva/panviva-react-ui';
import {UserGrid} from '../../components/users/UserGrid';

export default function Users() {
    return (
        <div className="up-w-full">
            <div className="up-bg-gray-10 up-items-center">
                <TitleBar title="Users" backOption={false}>
                    <Button icon="add" variant="outlined" disabled>
                        Add
                    </Button>
                </TitleBar>
                <UserGrid />
            </div>
        </div>
    );
}
