import {BottomLinks, Page} from '../../components/common/page';
import {AlertBar, Button, Chip, Typography} from '@panviva/panviva-react-ui';
import {
    fetchOrganizationByCode,
    getOrganization
} from '../../features/organizations/organizationSlice';
import getHttpClient, {ensureSuccessResponse} from '../../common/http-common';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {DetailsBlock} from '../../components/common/detailBlock';
import LoadingWBackdrop from '../../components/common/LoadingWBackdrop';
import PageNotFound from '../../components/PageNotFound';
import {useHistory} from 'react-router-dom';
import { AlertColor } from '../../common/types';
import '../../style/App.scss';

const OrganizationDetails = ({match}: any) => {
    const {organizationCode} = match.params;
    const [notification, setNotification] = useState<{
        text: string;
        severity: AlertColor;
    }>();

    const history = useHistory();
    const dispatch = useDispatch();
    const data = useSelector(getOrganization);

    useEffect(() => {
        (async () => {
            await dispatch(fetchOrganizationByCode(organizationCode));
        })();
    }, []);

    const closeNotification = () => {
        setNotification(undefined);
    };

    return (
        <div className="up-h-full up-w-full">
            {data.loading && <LoadingWBackdrop />}
            {data.organization && (
                <Page title={data.organization.name!} backOption={true}>
                    <div className="up-px-7">
                        <DetailsBlock
                            title="Organization Code"
                            data={data.organization.code}
                        />
                        <DetailsBlock
                            title="Organization Name"
                            data={data.organization.name}
                        />
                        <DetailsBlock
                            title="Region"
                            data={data.organization.region}
                        />
                        <DetailsBlock
                            title="Country"
                            data={data.organization.country}
                        />
                        <DetailsBlock
                            title="Allowed Identity Providers"
                            data={data.organization.allowedIdentityProviders?.map(
                                (identityProvider) => {
                                    return (
                                        <Chip
                                            label={identityProvider.name}
                                            key={
                                                identityProvider.identityProviderId
                                            }
                                            style={{
                                                display: 'inline-flex',
                                                justifyContent: 'center',
                                                width: 'max-content'
                                            }}
                                        />
                                    );
                                }
                            )}
                        />
                        <DetailsBlock
                            title="Default Identity Providers"
                            data={
                                data.organization.defaultIdentityProvider?.name
                            }
                        />
                        <DetailsBlock
                            title="Description"
                            data={data.organization.description}
                        />
                        <Typography variant="subtitle_1">Support</Typography>
                        <DetailsBlock
                            title="Support Request"
                            data={data.organization.supportRequestReference}
                        />
                        <DetailsBlock
                            title="Support Arrangement Type"
                            data={data.organization.supportArrangementType}
                        />
                        <DetailsBlock
                            title="Domains"
                            data={data.organization.domains.map((domain) => {
                                return (
                                    <Chip
                                        label={domain}
                                        key={domain}
                                        style={{
                                            display: 'inline-flex',
                                            justifyContent: 'center',
                                            width: 'min-content'
                                        }}
                                    />
                                );
                            })}
                        />
                    </div>
                    <BottomLinks>
                        <Button
                            label="Delete"
                            variant="outlined"
                            slot="actions"
                            className="up-mr-2"
                            onClick={() => {
                                (async () => {
                                    await getHttpClient()
                                        .delete(
                                            `organization/${organizationCode}`
                                        )
                                        .then(ensureSuccessResponse)
                                        .then(() => {
                                            history.goBack();
                                        })
                                        .then(() => {
                                            setNotification({
                                                text: 'Deleted organization',
                                                severity: 'information'
                                            });
                                        })
                                        .catch((err) => {
                                            setNotification({
                                                text: 'Could not delete organiation',
                                                severity: 'error'
                                            });
                                        });
                                })();
                            }}
                        />
                        <Button
                            is-link="true"
                            label="Edit"
                            variant="outlined"
                            slot="actions"
                            onClick={() => {
                                window.open(
                                    `../../organization/edit/${organizationCode}`,
                                    '_self'
                                );
                            }}
                        />
                    </BottomLinks>
                </Page>
            )}
            {data.error && <PageNotFound />}
            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};

export default OrganizationDetails;
