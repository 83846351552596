import {IconButton, TopBar} from '@panviva/panviva-react-ui';
import logo from '../assets/images/logo.png';

const Header = ({menuOpen, setMenuOpen, ...props}: any) => {
    return (
        <TopBar
            style={{height: 56}}
            navSize={56}
            icon="home"
            label="Administration"
            menuButton
            menuButtonOnClick={() => {
                setMenuOpen(!menuOpen);
            }}
            extraButtonOnClick={() => {
                window.location.assign('/');
            }}
            barButtons={
                <>
                    <IconButton
                        label="Help"
                        icon="help"
                        variant="inverse"
                    ></IconButton>
                    <IconButton
                        label="Notifications"
                        icon="notifications"
                        variant="inverse"
                    ></IconButton>
                </>
            }
            logo={logo}
            {...props}
        ></TopBar>
    );
};

export default Header;
