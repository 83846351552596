import {BottomLinks, Page} from '../../components/common/page';
import {Button, Typography} from '@panviva/panviva-react-ui';
import {useDispatch, useSelector} from 'react-redux';
import LoadingWBackdrop from '../../components/common/LoadingWBackdrop';
import {RootState} from '../../app/store';
import {retrieveUser} from '../../features/users/usersSlice';
import {useEffect} from 'react';

type IOrganization = {
    organizationId: number;
    code: string;
    name: string;
    description: string;
};

type ITenant = {
    organizationId: number;
    code: string;
    name: string;
    description: string;
};

type User = {
    id: string;
    firstName: string;
    lastName: string;
    description: string;
    emailAddress: string;
    linkedOktaId: string;
    isActive: boolean;
    organizations: IOrganization[];
    tenants: ITenant[];
};

const UserDetails = (params: any) => {
    const user = useSelector<RootState, User | null>(
        (state) => state.users.user
    );

    const loading = useSelector<RootState, boolean>(
        (state) => state.users.loading
    );

    const dispatch = useDispatch();
    const userId = params.match.params.userId;

    useEffect(() => {
        dispatch(retrieveUser(userId));
    }, [userId]);

    if (!user) {
        return <LoadingWBackdrop />;
    }

    return (
        <div className="up-h-full up-w-full up-bg-gray-10">
            {loading && <LoadingWBackdrop />}

            {user && (
                <Page
                    title={`${user.firstName} ${user.lastName}`}
                    backOption={true}
                >
                    <div
                        className="up-h-full"
                        style={{
                            backgroundColor: '#fff'
                        }}
                    >
                        <div className="up-px-7">
                            <div className="up-col-desktop-12 up-h-full">
                                <Typography variant="caption">
                                    Organization
                                    <br />
                                    <Typography variant="body_2">
                                        {user.organizations

                                            .map((o: IOrganization) => o.name)

                                            .join(', ')}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Tenant
                                    <Typography variant="body_2">
                                        {user.tenants

                                            .map((t: ITenant) => t.name)

                                            .join(', ')}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="subtitle_2">
                                    Basic info
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Full Name
                                    <br />
                                    <Typography variant="body_2">
                                        {user.firstName} {user.lastName}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Email
                                    <br />
                                    <Typography variant="body_2">
                                        {user.emailAddress}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Linked Okta Id
                                    <br />
                                    <Typography variant="body_2">
                                        {user.linkedOktaId}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Active
                                    <br />
                                    <Typography variant="body_2">
                                        {user.isActive ? 'Yes' : 'No'}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="subtitle_2">
                                    Roles
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Roles
                                    <br />
                                    <Typography variant="body_2">
                                        {'...'}
                                    </Typography>
                                </Typography>
                                <br />
                                <Typography variant="caption">
                                    Description
                                    <br />
                                    <Typography variant="body_2">
                                        {user.description}
                                    </Typography>
                                </Typography>
                            </div>
                        </div>
                        <BottomLinks>
                            <Button
                                disabled
                                label="Delete"
                                variant="outlined"
                            />
                            <Button disabled label="Edit" variant="outlined" />
                        </BottomLinks>
                    </div>
                </Page>
            )}
        </div>
    );
};

export default UserDetails;
