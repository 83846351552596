import getHttpClient from '../http-common';

export const Tenants = () =>
    getHttpClient()
        .get('Tenant?&$filter=isActive%20eq%20true&$select=code,name')
        .then((response) => response.data)
        .then((json) => json.values)
        .then((response) =>
            response.map((data: any) => ({
                tenantName: data.name,
                tenantCode: data.code
            }))
        )
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });

export const TenantNames = () =>
    Tenants()
        .then((data) => data.map((item: any) => item.tenantName))
        .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
        });
