/* eslint-disable indent */
import {useEffect, useState} from 'react';
import {LeftHandNav} from '@panviva/panviva-react-ui';
import {useHistory} from 'react-router-dom';
import {useOktaAuth} from '@okta/okta-react';

const Header = ({menuOpen, setMenuOpen}: any) => {
    const [activeLink, setActiveLink] = useState('');
    const {oktaAuth, authState} = useOktaAuth();
    const history = useHistory();

    const login = async () => {
        await oktaAuth.signInWithRedirect({
            originalUri: history.location.pathname
        });
    };
    const logout = async () => {
        await oktaAuth.signOut();
        localStorage.setItem('accessToken', '');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userPermissions');
        history.push('/');
    };

    useEffect(() => {
        setActiveLink(history.location.pathname);
    }, [history]);

    const routes = authState.isAuthenticated
        ? [
              {
                  label: 'Dashboard',
                  href: '/',
                  icon: 'reports-dashboard',
                  subMenu: false,
                  subMenuOptions: []
                  // clickHandler: toggleLink(0)
              },
              {
                  label: 'Users',
                  href: '/users',
                  icon: 'people',
                  subMenu: false,
                  subMenuOptions: []
                  // clickHandler: toggleLink(1)
              },
              {
                  label: 'Organizations',
                  href: '/organizations',
                  icon: 'home',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
              },
              {
                  label: 'Tenants',
                  href: '/tenants',
                  icon: 'reports-grid',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
                  // clickHandler: toggleLink(3)
              },
              {
                  label: 'Super Admins',
                  href: '/admins',
                  icon: 'person-add',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
              },
              {
                  label: 'Profile',
                  href: '/profile',
                  icon: 'user',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
              },
              {
                  label: 'Settings',
                  href: '/settings',
                  icon: 'configuration',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
              }
          ]
        : [];

    const fixedRoutes: any = authState.isAuthenticated
        ? [
              {
                  label: 'Activity Feed',
                  href: '/feed',
                  icon: 'report',
                  subMenu: false,
                  subMenuOptions: [],
                  hide: true
              },
              {
                  label: 'Logout',
                  clickHandler: logout,
                  icon: 'people',
                  subMenu: false,
                  subMenuOptions: []
              }
          ]
        : [
              {
                  label: 'Sign In',
                  clickHandler: login,
                  icon: 'people',
                  subMenu: false,
                  subMenuOptions: []
              }
          ];

    return (
        <LeftHandNav
            activeOption={activeLink}
            navSize={56}
            routes={routes.filter((route)=> !route.hide)}
            fixedOptions={fixedRoutes.filter((fixedRoute:any)=> !fixedRoute.hide)}
            externalToggle
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            style={{float: 'left', height: '100%'}}
        />
    );
};

export default Header;
