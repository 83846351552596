import {FunctionComponent} from 'react';
import {Typography} from '@panviva/panviva-react-ui';

const PageNotFound: FunctionComponent = () => {
    return (
        <div
            className="up-bg-gray-10 up-w-full up-h-full up-items-center up-justify-center up-mx-auto up-my-auto up-pt-10"
            style={{textAlign: 'center'}}
        >
            <Typography
                variant="title"
                className="up-font-bold up-pt-10 up-pb-5"
                style={{fontSize: '100px', color: '#838fa0'}}
            >
                404
            </Typography>
            <Typography
                variant="subtitle_1"
                className="up-font-bold"
                style={{fontSize: '50px', color: '#838fa0'}}
            >
                Oops! Not Found
            </Typography>
            <Typography
                variant="title"
                className=" up-font-semibold"
                style={{color: '#838fa0'}}
            >
                The page you are looking for might have been removed or had its
                <br /> name changed or is temporarily unavailable.
                <br />
                <br />
                Please try after sometime. Else, contact Support.
            </Typography>
        </div>
    );
};

export default PageNotFound;
