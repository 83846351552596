import {AlertBar, Button, Typography} from '@panviva/panviva-react-ui';
import {
    DomainsInputHelper,
    getMaxDescription
} from '../../components/common/textConstants';
import {
    addOrganization,
    fetchOrganizationByCode,
    patchOrganization,
    setNotification,
    setOrganization
} from '../../features/organizations/organizationSlice';
import {useDispatch, useSelector} from 'react-redux';
import AsynchronousDropdownSelect from '../../components/common/asynchronousDropdownSelect';
import CountryList from '../../common/data/countries';
import {DetailsBlock} from '../../components/common/detailBlock';
import DropdownSelect from '../../components/common/dropdownSelect';
import {IdentityProviders} from '../../common/data/identityProviders';
import JsonPatch from 'fast-json-patch';
import LoadingWBackdrop from '../../components/common/LoadingWBackdrop';
import {Page} from '../../components/common/page';
import PageNotFound from '../../components/PageNotFound';
import Regions from '../../common/data/regions';
import {RootState} from '../../app/store';
import {SerializedError} from '@reduxjs/toolkit';
import SupportArrangementTypes from '../../common/data/supportArrangementTypes';
import TagsDropdownInput from '../../components/common/tagsDropdownInput';
import TagsInput from '../../components/common/TagsInput';
import TextInput from '../../components/common/textInput';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import '../../style/App.scss';

interface IIdentifierProvider {
    name: string;
    identityProviderId: string;
    id: number;
}

const EditOrganizationPage = (params: any) => {
    const clearOrganization = {
        code: undefined,
        name: undefined,
        description: undefined,
        supportRequestReference: undefined,
        supportArrangementType: undefined,
        domains: [],
        region: undefined,
        country: undefined,
        defaultIdentityProvider: null as any,
        allowedIdentityProviders: [] as any[],
        defaultDomain: undefined
    };

    const dispatch = useDispatch();

    const organization = useSelector<RootState, any | null>(
        (state) => state.organization.organization
    );

    const initialOrganization = useSelector<RootState, any | null>(
        (state) => state.organization.initialOrganization
    );

    const loading = useSelector<RootState, boolean>(
        (state) => state.organization.loading
    );

    const error = useSelector<RootState, SerializedError>(
        (state) => state.organization.error
    );

    const notification = useSelector<RootState, any | null>(
        (state) => state.organization.notification
    );

    const errors = useSelector<RootState, string[]>(
        (state) => state.organization.errors
    );

    const {enqueueSnackbar} = useSnackbar();

    const getCreateOrganizationRequest = (
        baseOrganization: typeof clearOrganization
    ) => {
        return {
            ...baseOrganization,
            defaultIdentityProvider:
                baseOrganization.defaultIdentityProvider &&
                baseOrganization.defaultIdentityProvider.identityProviderId,
            allowedIdentityProviders:
                baseOrganization.allowedIdentityProviders &&
                baseOrganization.allowedIdentityProviders.map(
                    (idp) => idp.identityProviderId
                )
        };
    };

    const getSaveRequest = async () => {
        if (!params.match.params.organizationCode) {
            return dispatch(
                addOrganization(getCreateOrganizationRequest(organization))
            );
        }

        const request = JsonPatch.compare(
            getCreateOrganizationRequest(initialOrganization),
            getCreateOrganizationRequest(organization)
        );

        return dispatch(
            patchOrganization({
                orgCode: organization.code!,
                patchObject: request
            })
        );
    };

    const handleTextInputChange = (e: any) => {
        const {name, value} = e.target;

        dispatch(
            setOrganization({
                ...organization,
                [name]: value
            })
        );
    };

    const handleSelectInputChange = (name: string, e: any, value: any) => {
        dispatch(
            setOrganization({
                ...organization,
                [name]: value
            })
        );
    };

    const handleOktaIdpSelectInputChange = (e: any, value: any) => {
        dispatch(
            setOrganization({
                ...organization,
                defaultIdentityProvider: value
            })
        );
    };

    const saveOrganization = () => {
        (async () => {
            await getSaveRequest().then((res: any) => {
                if (res.meta.requestStatus === 'fulfilled') {
                    setTimeout(() => {
                        history.goBack();
                    }, 1000);
                }
            });
        })();
    };

    const closeNotification = () => {
        dispatch(setNotification(undefined));
    };

    const history = useHistory();

    const closeOrganizationEditScreen = () => {
        history.goBack();
    };

    useEffect(() => {
        if (params.match.params.organizationCode) {
            (async () => {
                await dispatch(
                    fetchOrganizationByCode(
                        params.match.params.organizationCode
                    )
                );
            })();
        }

        errors.map((e: string) => {
            return enqueueSnackbar(e, {
                variant: 'error'
            });
        });
    }, [errors]);

    return (
        <div className="up-h-full up-w-full">
            {loading && <LoadingWBackdrop />}
            {error && <PageNotFound />}
            {organization && (
                <Page
                    title={
                        (params.match.params.organizationCode &&
                            `Edit ${organization.name}`) ||
                        'Add Organization'
                    }
                    backOption={true}
                >
                    <div className="up-px-7">
                        <Typography
                            variant="caption"
                            className=" up-mb-4 text-color-50"
                            style={{color: '#838fa0'}}
                        >
                            *Required Fields
                        </Typography>
                        <Typography variant="subtitle_1">
                            Organization Details
                        </Typography>
                        <DetailsBlock
                            title="Organization Code"
                            data={organization.code}
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Name"
                            name="name"
                            onChange={handleTextInputChange}
                            value={organization.name || ''}
                            required
                            autoFocus
                        />
                        <DropdownSelect
                            style={{marginBottom: 32, position: 'relative', width: '100%'}}
                            name="region"
                            title="Region"
                            onInputChange={handleSelectInputChange.bind(
                                this,
                                'region'
                            )}
                            value={organization.region || null}
                            options={Regions}
                            required
                        />
                        <AsynchronousDropdownSelect
                            style={{marginBottom: 32, position: 'relative', width: '100%'}}
                            dropdownStyle={{height:"200px"}}
                            name="country"
                            title="Country"
                            onChange={handleSelectInputChange.bind(
                                this,
                                'country'
                            )}
                            asyncloader={CountryList}
                            value={organization.country || null}
                            required
                        />
                        <AsynchronousDropdownSelect
                            style={{marginBottom: 32, position: 'relative', width: '100%'}}
                            dropdownStyle={{height:"200px"}}
                            name="defaultIdentityProvider"
                            title="Default Identity Provider"
                            onChange={handleOktaIdpSelectInputChange}
                            asyncloader={IdentityProviders}
                            value={organization.defaultIdentityProvider || null}
                            required
                        />
                        <TagsDropdownInput
                            style={{marginBottom: 32, position: 'relative', width: '100%'}}
                            dropdownStyle={{height:"200px"}}
                            name="allowedIdentityProvider"
                            title="Allowed Identity Provider"
                            onChange={(e: any, value: any) => {
                                dispatch(
                                    setOrganization({
                                        ...organization,
                                        allowedIdentityProviders: value
                                    })
                                );
                            }}
                            asyncloader={IdentityProviders}
                            value={organization.allowedIdentityProviders || []}
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Description"
                            name="description"
                            onChange={handleTextInputChange}
                            helperText={getMaxDescription(500)}
                            value={organization.description || ''}
                            required
                            multiline
                            rows={5}
                        />
                        <Typography variant="subtitle_1">Support</Typography>
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Support Request"
                            name="supportRequestReference"
                            onChange={handleTextInputChange}
                            value={organization.supportRequestReference || ''}
                        />
                        <DropdownSelect
                            style={{marginBottom: 32, position: 'relative', width: '100%'}}
                            name="supportArrangementType"
                            title="Support Arrangement Type"
                            onInputChange={handleSelectInputChange.bind(
                                this,
                                'supportArrangementType'
                            )}
                            value={organization.supportArrangementType || null}
                            options={SupportArrangementTypes}
                        />
                        <Typography variant="subtitle_1">
                            Organization Domains
                        </Typography>
                        <TagsInput
                            style={{marginBottom: 32}}
                            label="Domains"
                            name="domains"
                            helperText={DomainsInputHelper}
                            tags={organization.domains}
                            onChange={(tags: any) => {
                                dispatch(
                                    setOrganization({
                                        ...organization,
                                        domains: tags
                                    })
                                );
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: 4
                            }}
                        >
                            <Button
                                label="Cancel"
                                variant="outlined"
                                onClick={closeOrganizationEditScreen}
                            />
                            <Button label="Save" onClick={saveOrganization} />
                        </div>
                    </div>
                </Page>
            )}
            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};

export default EditOrganizationPage;
