import {AlertBar, Button, Typography} from '@panviva/panviva-react-ui';
import {
    DomainsInputHelper,
    getMaxDescription
} from '../../components/common/textConstants';
import {
    addTenant,
    fetchTenantByCode,
    patchTenant,
    setNotification,
    setTenant
} from '../../features/tenants/tenantSlice';
import {useDispatch, useSelector} from 'react-redux';
import AsynchronousDropdownSelect from '../../components/common/asynchronousDropdownSelect';
import {DetailsBlock} from '../../components/common/detailBlock';
import DropdownSelect from '../../components/common/dropdownSelect';
import {IdentityProviders} from '../../common/data/identityProviders';
import JsonPatch from 'fast-json-patch';
import LoadingWBackdrop from '../../components/common/LoadingWBackdrop';
import {Organizations} from '../../common/data/organizations';
import {Page} from '../../components/common/page';
import PageNotFound from '../../components/PageNotFound';
import Regions from '../../common/data/regions';
import {RootState} from '../../app/store';
import {SerializedError} from '@reduxjs/toolkit';
import TagsDropdownInput from '../../components/common/tagsDropdownInput';
import TagsInput from '../../components/common/TagsInput';
import TextInput from '../../components/common/textInput';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import '../../style/App.scss';

interface IOrganization {
    organizationName: string;
    organizationCode: string;
}
interface IIdentifierProvider {
    name: string;
    identityProviderId: string;
    id: number;
}

const RequiredFields = [
    {
        name: 'name',
        label: 'Name'
    },
    {
        name: 'tenantUrl',
        label: 'Tenant URL'
    },
    {
        name: 'region',
        label: 'Region'
    },
    {
        name: 'organizationCode',
        label: 'Organization'
    },
    {
        name: 'description',
        label: 'Description'
    },
    {
        name: 'version',
        label: 'Version'
    },
    {
        name: 'defaultDomain',
        label: 'Default Domain'
    },
    {
        name: 'oldUrl',
        label: 'Old URL'
    },
    {
        name: 'oldInstanceName',
        label: 'Old Instance Name'
    }
];

const EditTenantPage = (params: any) => {
    const dispatch = useDispatch();

    const tenant = useSelector<RootState, any | null>(
        (state) => state.tenant.tenant
    );

    const initialTenant = useSelector<RootState, any | null>(
        (state) => state.tenant.initialTenant
    );

    const loading = useSelector<RootState, boolean>(
        (state) => state.tenant.loading
    );

    const error = useSelector<RootState, SerializedError>(
        (state) => state.tenant.error
    );

    const notification = useSelector<RootState, any | null>(
        (state) => state.tenant.notification
    );

    const errors = useSelector<RootState, string[]>(
        (state) => state.tenant.errors
    );

    const {enqueueSnackbar} = useSnackbar();

    const history = useHistory();

    const getTenantPayload = (baseTenant: any) => {
        return {
            ...baseTenant,
            defaultIdentityProvider:
                baseTenant.defaultIdentityProvider &&
                baseTenant.defaultIdentityProvider.identityProviderId,
            allowedIdentityProviders:
                baseTenant.allowedIdentityProviders &&
                baseTenant.allowedIdentityProviders.map(
                    (idp: any) => idp.identityProviderId
                )
        };
    };

    const getSaveRequest = async () => {
        if (!params.match.params.tenantCode) {
            return dispatch(addTenant(getTenantPayload(tenant)));
        }

        const request = JsonPatch.compare(
            getTenantPayload(initialTenant),
            getTenantPayload(tenant)
        );

        return dispatch(
            patchTenant({
                tenantCode: tenant.code!,
                patchObject: request
            })
        );
    };

    const handleTextInputChange = (e: any) => {
        const {name, value} = e.target;

        dispatch(
            setTenant({
                ...tenant,
                [name]: value
            })
        );
    };

    const handleSelectInputChange = (name: string, e: any, value: any) => {
        dispatch(
            setTenant({
                ...tenant,
                [name]: value
            })
        );
    };

    const handleOrganizationInputChange = (e: any, value: any) => {
        dispatch(
            setTenant({
                ...tenant,
                organizationCode: value.organizationCode,
                organizationName: value.organizationName
            })
        );
    };

    const handleOktaIdpSelectInputChange = (e: any, value: any) => {
        dispatch(
            setTenant({
                ...tenant,
                defaultIdentityProvider: value
            })
        );
    };

    const saveTenant = () => {
        let validRequest = true;

        RequiredFields.forEach((field) => {
            if (
                tenant[field.name] === undefined ||
                tenant[field.name] === null ||
                tenant[field.name] === ''
            ) {
                validRequest = false;
                enqueueSnackbar(`'${field.label}' must not be empty`, {
                    variant: 'error'
                });
            }
        });

        if (!validRequest) {
            return;
        }

        (async () => {
            await getSaveRequest().then((res: any) => {
                if (res.meta.requestStatus === 'fulfilled') {
                    setTimeout(() => {
                        history.goBack();
                    }, 1000);
                }
            });
        })();
    };

    const closeNotification = () => {
        dispatch(setNotification(undefined));
    };

    const closeTenantEditScreen = () => {
        history.goBack();
    };

    useEffect(() => {
        if (params.match.params.tenantCode) {
            (async () => {
                await dispatch(
                    fetchTenantByCode(params.match.params.tenantCode)
                );
            })();
        }

        errors.map((e: string) => {
            return enqueueSnackbar(e, {
                variant: 'error'
            });
        });
    }, [errors]);

    return (
        <div className="up-h-full up-w-full up-bg-gray-10">
            {loading && <LoadingWBackdrop />}
            {error && <PageNotFound />}
            {tenant && (
                <Page
                    title={
                        (params.match.params.tenantCode &&
                            `Edit ${tenant.name}`) ||
                        'Add Tenant'
                    }
                    backOption={true}
                >
                    <div className="up-px-7">
                        <Typography
                            variant="caption"
                            className="up-mb-4 text-color-50"
                            style={{color: '#838fa0'}}
                        >
                            *Required Fields
                        </Typography>
                        <Typography variant="subtitle_1">
                            Tenant Details
                        </Typography>
                        <DetailsBlock title="Tenant Code" data={tenant.code} />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Name"
                            name="name"
                            onChange={handleTextInputChange}
                            value={tenant.name || ''}
                            required
                            autoFocus
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Tenant URL"
                            name="tenantUrl"
                            onChange={handleTextInputChange}
                            value={tenant.tenantUrl || ''}
                            required
                        />
                        <DropdownSelect
                            style={{marginBottom: 32, position:'relative', width: '100%'}}
                            name="region"
                            title="Region"
                            onInputChange={handleSelectInputChange.bind(
                                this,
                                'region'
                            )}
                            value={tenant.region || null}
                            options={Regions}
                            required
                        />
                        <AsynchronousDropdownSelect
                            style={{marginBottom: 32, position:'relative', width: '100%'}}
                            name="organizationCode"
                            title="Organization"
                            onChange={handleOrganizationInputChange}
                            asyncloader={Organizations}
                            value={tenant || null}
                            required
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Description"
                            name="description"
                            onChange={handleTextInputChange}
                            helperText={getMaxDescription(250)}
                            value={tenant.description || ''}
                            required
                            multiline
                            rows={5}
                            inputProps={{maxLength: 250}}
                        />
                        <AsynchronousDropdownSelect
                            style={{marginBottom: 32, position:'relative', width: '100%'}}
                            dropdownStyle={{height:"200px"}}
                            name="defaultIdentityProvider"
                            title="Default Identity Provider"
                            onChange={handleOktaIdpSelectInputChange}
                            asyncloader={IdentityProviders}
                            value={tenant.defaultIdentityProvider || null}
                        />
                        <TagsDropdownInput
                            style={{marginBottom: 32, position:'relative', width: '100%'}}
                            dropdownStyle={{height:"200px"}}
                            name="allowedIdentityProvider"
                            title="Allowed Identity Provider"
                            onChange={(e: any, value: any) => {
                                dispatch(
                                    setTenant({
                                        ...tenant,
                                        allowedIdentityProviders: value
                                    })
                                );
                            }}
                            asyncloader={IdentityProviders}
                            value={tenant.allowedIdentityProviders || []}
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Default Domain"
                            name="defaultDomain"
                            onChange={handleTextInputChange}
                            value={tenant.defaultDomain || ''}
                            required
                        />
                        <TagsInput
                            style={{marginBottom: 32}}
                            label="Domains"
                            name="domains"
                            helperText={DomainsInputHelper}
                            tags={tenant.domains}
                            onChange={(tags: any) => {
                                dispatch(
                                    setTenant({
                                        ...tenant,
                                        domains: tags
                                    })
                                );
                            }}
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Version"
                            name="version"
                            onChange={handleTextInputChange}
                            value={tenant.version || ''}
                            required
                        />
                        <Typography variant="subtitle_1">
                            Old Instance
                        </Typography>
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Old SPS Details"
                            name="spsDetails"
                            onChange={handleTextInputChange}
                            value={tenant.spsDetails || ''}
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Old URL"
                            name="oldUrl"
                            onChange={handleTextInputChange}
                            value={tenant.oldUrl || ''}
                            required
                        />
                        <TextInput
                            style={{marginBottom: 32}}
                            label="Old Instance Name"
                            name="oldInstanceName"
                            onChange={handleTextInputChange}
                            required
                            value={tenant.oldInstanceName || ''}
                        />

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: 4
                            }}
                        >
                            <Button
                                label="Cancel"
                                variant="outlined"
                                onClick={closeTenantEditScreen}
                            />
                            <Button label="Save" onClick={saveTenant} />
                        </div>
                    </div>
                </Page>
            )}

            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};

export default EditTenantPage;
