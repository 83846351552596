import {Chip, Textfield} from '@panviva/panviva-react-ui';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

export default function TagsInput({...props}) {
    // eslint-disable-next-line react/prop-types
    const {tags, onChange, ...other} = props;
    const [inputValue, setInputValue] = useState<string>('');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        setSelectedItems(tags);
    }, [tags]);

    function handleKeyDown(event: any) {
        if (event.key === ';' || event.key === 'Enter') {
            checkForAdditionKP(event);
        } else if (event.key === 'Backspace') {
            checkForRemovalKP();
        }
    }

    const checkForRemovalKP = () => {
        if (selectedItems.length && !inputValue) {
            setSelectedItems((prev) => {
                const newSelections = [...prev];

                newSelections.pop();

                return newSelections;
            });
        }
    };

    const checkForAdditionKP = (event: any) => {
        event.preventDefault();
        const newValue = event.target.value.trim();

        if (!newValue) return;

        const newSelectedItem = [...selectedItems];

        const duplicatedValues = newSelectedItem.indexOf(newValue);

        if (duplicatedValues !== -1) {
            clearInput();
        } else {
            newSelectedItem.push(newValue);
            setSelectedItems(newSelectedItem);

            clearInput();

            if (onChange) {
                onChange(newSelectedItem);
            }
        }
    };

    const clearInput = () => {
        setInputValue('');
    };

    const handleDelete = (item: string) => () => {
        const newSelectedItem = [...selectedItems];

        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItems(newSelectedItem);

        if (onChange) {
            onChange(newSelectedItem);
        }
    };

    function handleInputChange(event: any) {
        setInputValue(event.target.value as string);
    }

    const chipsMarkup = selectedItems.map((item) => (
        <Chip
            key={item}
            tabIndex={-1}
            label={item}
            onAction={handleDelete(item)}
        />
    ));

    return (
        <div style={{minWidth: '100%'}}>
            <div
                className="up-caption up-mb-2 up-font-semibold text-color-50"
                style={{color: '#838fa0'}}
            >
                {chipsMarkup}
            </div>

            <Textfield
                onKeyDown={(e: any) => handleKeyDown(e)}
                onChange={(e: any) => handleInputChange(e)}
                value={inputValue || ''}
                {...other}
            />
        </div>
    );
}
TagsInput.defaultProps = {
    tags: []
};
TagsInput.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string)
};
