
import {useEffect, useState} from 'react';
import {Chip, Select} from '@panviva/panviva-react-ui';
import './common.scss';

export default function TagsInput(props: any) {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const [selected, setSelected] = useState<any[]>([]);
    const [chips, setChips] = useState<any>(null);
    const loading = open && options.length === 0;

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            await props.asyncloader().then((data: any) => {
                if (active) {
                    setOptions(data);
                }
            });
        })();

        return () => {
            active = false;
        };
    }, [loading]);

    useEffect(() => {
        if (!open) {
            setOptions([]);
        }
    }, [open]);

    useEffect(()=>{
        const chips = selected.map((item) => <Chip label={item.name} action actionIcon="cancel" style={{margin:"2px"}} onAction={() => onChipRemove(item)} />);
        setChips(chips);
      }, [selected])

      const onChipRemove = (value:any) =>{
        setSelected(selected?.filter((item) => item.name !== value.name));
      }

    function handleClickSelection (option:any) {
        const selectedIndex = selected.findIndex(
            (item: any) => item.name === option.name
        );
        let newSelected: any[] = [];
      
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, option);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
        props.onChange(event, newSelected);
    }

    return (
        <div>
            <Select loading={loading}
                    multiple
                    chips={chips}
                    label={`${props.title}${(props.required && ' *') || ''}`} 
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    dropdownClass='dropdown_content'
                    dropdownStyle={props.dropdownStyle}
                    selected={selected}
                    style={props.style}
                    >
                {
                    options?.map((option:any)=>{
                        return <div key={option.name} className='menu_item'>
                     <input 
                     type="checkbox"
                     value={option.name} 
                     checked={selected?.find((item)=>item.name === option.name)} onChange={()=>handleClickSelection(option)}/>&nbsp;
                     <label>{option.name}</label>
                   </div>
                    })               
                }
            </Select>
        </div>
    );
}
