import {configureStore} from '@reduxjs/toolkit';
import organizationReducer from '../features/organizations/organizationSlice';
import tenantReducer from '../features/tenants/tenantSlice';
import usersReducer from '../features/users/usersSlice';

export const store = configureStore({
    reducer: {
        users: usersReducer,
        organization: organizationReducer,
        tenant: tenantReducer
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
