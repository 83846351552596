import {useHistory} from 'react-router-dom';
import { Loader, SupportedEntities } from '../grid/dataLoader';
import { AsyncDropDownColumnMenu } from '../grid/asyncDropDownFilter';
import { DateColumnMenu } from '../grid/dateFilter';
import { NavLink } from 'react-router-dom';
import { NumericColumnMenu } from '../grid/numericFilter';
import React from 'react';
import RegionsList from '../../common/data/regions';
import { TextColumnMenu } from '../grid/textFilter';
import getHttpClient, {
    ensureAcceptedResponse,
    ensureSuccessResponse
} from '../../common/http-common';
import { OrganizationNames } from '../../common/data/organizations';
import { convertDateToLocale } from '../../common/utils';
import { postReactivateUsersInTenant } from '../../features/oktaWorkflows/oktaWorkflowService';
import { AlertBar, Button, Dropdown, HeaderField, Table, TableBody, TableField, TableHeader, TableRow, Textfield, UpIcon } from '@panviva/panviva-react-ui';
import { IDataResult, IDataState } from '../grid/dataLoader';
import { AlertColor } from '../../common/types';
import '../grid/gridStyles.scss';
import '../../style/App.scss';

enum GridColumns {
    Name = 'name',
    Code = 'code',
    Region = 'region',
    OrganizationName = 'organizationName',
    Version = 'version',
    TenantURL = 'tenantUrl',
    NumberOfUsers = 'numberOfUsers',
    UpdatedDate = 'updatedDate'
}

const GridFields = {
    [GridColumns.Name]: 'name',
    [GridColumns.Code]: 'code',
    [GridColumns.Region]: 'region',
    [GridColumns.OrganizationName]: 'organization/name',
    [GridColumns.TenantURL]: 'tenantUrl',
    [GridColumns.NumberOfUsers]: 'numberOfUsers',
    [GridColumns.UpdatedDate]: 'updatedDate',
    [GridColumns.Version]: 'version'
};

const GridTitles = {
    [GridColumns.Name]: 'Tenant Name',
    [GridColumns.Code]: 'Tenant Code',
    [GridColumns.Region]: 'Region',
    [GridColumns.OrganizationName]: 'Organization Name',
    [GridColumns.TenantURL]: 'Tenant URL',
    [GridColumns.NumberOfUsers]: 'Users',
    [GridColumns.UpdatedDate]: 'Last Activity',
    [GridColumns.Version]: 'Version'
};

const ODataFields = {
    [GridColumns.Code]: 'code',
    [GridColumns.Name]: 'name',
    [GridColumns.Region]: 'region',
    [GridColumns.OrganizationName]: 'organization/name',
    [GridColumns.TenantURL]: 'tenantUrl',
    [GridColumns.Version]: 'version'
};

const transformData = (data: any[]) => {
    return data.map((item) => {
        return {
            ...item,
            [GridFields[GridColumns.OrganizationName]]: item.organizationName
        };
    });
};

export const TenantGrid = (): JSX.Element => {
    const history = useHistory();
    const loader = React.useRef<React.ElementRef<typeof Loader>>(null);
    const [notification, setNotification] = React.useState<{
        text: string;
        severity: AlertColor;
    }>();
    const [tenants, setTenants] = React.useState<IDataResult>({ data: [], total: 0 });
    const [dataState, setDataState] = React.useState<IDataState>({
        take: 10,
        skip: 0,
        page: 1,
        filter: { logic: 'and', filters: [] }
    });
    const [searchString, setSearchString] = React.useState('');
    const [appliedSearchString, setAppliedSearchString] = React.useState<
        string | undefined
    >(undefined);

    const handleDelete = (code: string) => {
        (async () => {
            await getHttpClient()
                .delete(
                    `tenant/${code}`
                )
                .then(ensureSuccessResponse)
                .then(() => {
                    setNotification({
                        text: 'Deleted tenant',
                        severity: 'information'
                    });

                    if (loader.current) {
                        loader.current.refreshData();
                    }
                })
                .catch((err) => {
                    setNotification({
                        text: 'Could not delete tenant',
                        severity: 'error'
                    });
                });
        })();
    };

    const handleReactivationEmailTrigger = (code: string) => {
        (async () => {
            await postReactivateUsersInTenant(code)
                .then(ensureAcceptedResponse)
                .then(() => {
                    setNotification({
                        text: 'Resent Activation Email',
                        severity: 'information'
                    });
                })
                .catch((err) => {
                    setNotification({
                        text: 'Error resending reactivation email',
                        severity: 'error'
                    });
                });
        })();
    };

    const dataReceived = (tenantsResult: IDataResult) => {
        setTenants(tenantsResult);
    };

    const closeNotification = () => {
        setNotification(undefined);
    };

    const filterChanged = () => {
        setAppliedSearchString(searchString);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setDataState({ ...dataState, take: parseInt(event.target.value, 10), skip: 0, page: 1 })
    }

    const handleFirstPageChange = () => {
        setDataState({ ...dataState, skip: 0, page: 1 });
    }

    const handlePrevPageChange = () => {
        setDataState({ ...dataState, skip: dataState.skip - dataState.take, page: dataState.page - 1 })
    }

    const handleNextPageChange = () => {
        setDataState({ ...dataState, skip: dataState.skip + dataState.take, page: dataState.page + 1 })
    }

    const handleLastPageChange = () => {
        setDataState({ ...dataState, skip: dataState.page * (dataState.take), page: Math.ceil(tenants.total / dataState.take) })
    }

    const handleFilterApply = (field: string, value: any, operator:string="eq", hasSecondFilter:boolean = false) => {
        if(hasSecondFilter){
            handleSecondFilter(field, value);
        }else{
        const newObj = { field, value, operator };
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        const index = dataState.filter.filters.findIndex(obj => obj.field === field);
        if (index > -1) {
            value ? updatedFilters[index].value = value : updatedFilters.splice(index, 1)
        } else {
            value && updatedFilters.push(newObj);
        }
        setDataState({ ...dataState, filter: { logic: 'and', filters: updatedFilters } });
        }
    }

    const handleSecondFilter = (field: string, value: any) =>{
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        Object.keys(value).forEach((element:any) => {
            const index = dataState.filter.filters.findIndex(obj => obj.field === field && obj.operator === value[element].operator);
            const newObj = { field, value: value[element].value , operator: value[element].operator };
            if (index > -1) {
                value ? updatedFilters[index].value = value[element].value : updatedFilters.splice(index, 1)
            } else {
                value && updatedFilters.push(newObj);
            }
        });
        setDataState({ ...dataState, filter: { logic: 'and', filters: updatedFilters } });
    }

    const handleFilterClear = (field: string) => {
        const updatedFilters = JSON.parse(JSON.stringify(dataState.filter?.filters));
        const filteredList = updatedFilters.filter((obj:any) => obj.field !== field);
        setDataState({ ...dataState, filter: { logic: 'and', filters: filteredList } });
    }

    return (
        <div>
            <div
                className="up-w-full up-flex up-bg-blue-0"
                style={{
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    minHeight: 'fit-content',
                    padding: 2
                }}
            >
                <Textfield
                    label="Search"
                    icon="search"
                    value={searchString || ''}
                    style={{
                        width: 227,
                        paddingRight: 14,
                        marginBottom: 8
                    }}
                    onChange={(e: any) => {
                        setSearchString(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            filterChanged();
                        }
                    }}
                    onClickIcon={() => filterChanged()}
                />
            </div>

            <Table
                className={'table-container'}
                totalCount={tenants.total}
                totalPages={Math.ceil(tenants.total / dataState.take)}
                currentPage={dataState.page}
                rowsPerPage={dataState.take}
                setRowsPerPage={(e: React.ChangeEvent<HTMLSelectElement>) => handleChangeRowsPerPage(e)}
                handleFirstPage={handleFirstPageChange}
                handlePrevPage={handlePrevPageChange}
                handleNextPage={handleNextPageChange}
                handleLastPage={handleLastPageChange}
            >
                <TableHeader>
                    <TableRow type="header" style={{ fontSize: '14px' }}>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Name]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.Name]}
                                    field={GridFields[GridColumns.Name]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Name])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.OrganizationName]}{' '}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.OrganizationName]}
                                    field={GridFields[GridColumns.OrganizationName]}
                                    asyncloader={OrganizationNames}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.OrganizationName])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Code]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.Code]}
                                    field={GridFields[GridColumns.Code]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Code])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Region]}
                                <AsyncDropDownColumnMenu
                                    title={GridTitles[GridColumns.Region]}
                                    field={GridFields[GridColumns.Region]}
                                    menuOptions={RegionsList}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Region])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.TenantURL]}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.TenantURL]}
                                    field={GridFields[GridColumns.TenantURL]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.TenantURL])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.NumberOfUsers]}{' '}
                                <NumericColumnMenu
                                    title={GridTitles[GridColumns.NumberOfUsers]}
                                    field={GridFields[GridColumns.NumberOfUsers]}
                                    firstFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.NumberOfUsers] && obj.operator === 'ge')?.value}
                                    secondFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.NumberOfUsers] && obj.operator === 'le')?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.Version]}{' '}
                                <TextColumnMenu
                                    title={GridTitles[GridColumns.Version]}
                                    field={GridFields[GridColumns.Version]}
                                    value={dataState.filter.filters?.find(obj => obj.field === GridFields[GridColumns.Version])?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            <span className='column-header'>
                                {GridTitles[GridColumns.UpdatedDate]}{' '}
                                <DateColumnMenu
                                    title={GridTitles[GridColumns.UpdatedDate]}
                                    field={GridFields[GridColumns.UpdatedDate]}
                                    firstFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.UpdatedDate] && obj.operator === 'ge')?.value}
                                    secondFilterValue={dataState.filter.filters?.find(obj => 
                                        obj.field === GridFields[GridColumns.UpdatedDate] && obj.operator === 'le')?.value}
                                    onFilterApply={handleFilterApply}
                                    onClear={handleFilterClear} />
                            </span>
                        </HeaderField>
                        <HeaderField>
                            {" "}
                        </HeaderField>
                    </TableRow>
                </TableHeader>
                {tenants.total ?
                    <TableBody>
                        {tenants?.data?.map(dataItem => {
                            return <TableRow key={dataItem[GridFields[GridColumns.Code]]}>
                                <TableField>
                                    <NavLink
                                        key={dataItem[GridFields[GridColumns.Code]]}
                                        to={`tenant/details/${dataItem[GridFields[GridColumns.Code]]
                                            }`}
                                        className="label up-body-2"
                                        style={{ color: '#2574db', textDecoration: 'none' }}
                                    >
                                        {
                                            dataItem[
                                            GridFields[GridColumns.Name]
                                            ]
                                        }
                                    </NavLink>
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.OrganizationName]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Code]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Region]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.TenantURL]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.NumberOfUsers]]}
                                </TableField>
                                <TableField>
                                    {dataItem[GridFields[GridColumns.Version]]}
                                </TableField>
                                <TableField>
                                    <span className="up-caption text-color-gray-30">
                                        Updated
                                    </span>
                                    <br />
                                    {convertDateToLocale(
                                        dataItem[
                                        GridFields[GridColumns.UpdatedDate]
                                        ]
                                    )}
                                </TableField>
                                <TableField>
                                        <Dropdown
                                            id="single-menu"
                                            label="SingleMenu"                                            
                                            iconType
                                            menuContainerClassName="margin_left_extended_negative"
                                        >
                                            <div style={{backgroundColor:"white"}}>
                                            <Button
                                                variant="text"
                                                label='Edit'
                                                color='gray'
                                                capitalize
                                                onClick={() => {history.push(`tenant/edit/${dataItem[GridColumns.Code]}`)}}
                                            />
                                            <Button
                                                variant="text"
                                                label='Delete'
                                                color='gray'
                                                capitalize
                                                onClick={() => handleDelete(dataItem[GridColumns.Code])}
                                            />
                                            <Button
                                                variant="text"
                                                label='Resend Activation Email'
                                                color='gray'
                                                capitalize
                                                onClick={() => handleReactivationEmailTrigger(dataItem[GridColumns.Code])}
                                            />
                                            </div>
                                        </Dropdown>
                                </TableField>
                            </TableRow>
                        })}
                    </TableBody>
                    :
                    <div className='table-empty'>No rows to display</div>
                }
            </Table>

            <Loader
                ref={loader}
                dataState={dataState}
                searchString={appliedSearchString}
                searchStringSupportedFields={[
                    ODataFields[GridColumns.Code],
                    ODataFields[GridColumns.Name],
                    ODataFields[GridColumns.Region],
                    ODataFields[GridColumns.OrganizationName],
                    ODataFields[GridColumns.TenantURL],
                    ODataFields[GridColumns.Version]
                ]}
                onDataReceived={dataReceived}
                entityName={SupportedEntities.Tenant}
                transformData={transformData}
            />

            {notification && (
                <AlertBar
                    active
                    className='alert_container'
                    message={notification.text}
                    status={notification.severity}
                    onClose={closeNotification}
                />
            )}
        </div>
    );
};
